<template>
<div>
    <div class="full">
        <div class="full">
            <div class="mainheader">
                <div class="half">
                    Änderungsmeldungen
                </div>
            </div>
        </div>


        <div v-if="changelogs != undefined && changelogs.length > 0" class="full">
            <b-table class="documents" striped hover :borderless="true" :outlined="false" :items="filesFiltered" :fields="fields">
                <template v-slot:cell(link)="row">
                    <a :href="row.item.url+'/api/file?id='+row.item.fileId+'&accessToken='+row.item.accessToken" target="_blank"><i class="fas fa-eye"></i></a>
                </template>
                <template v-slot:cell(actions)="row">
                    <div class="button small mright8" v-if="row.item.report && row.item.report.fileserverID && row.item.report.fileserverAccessToken" v-on:click="openFile(row)"><div class="label">öffnen</div></div>
                </template>
            </b-table>
        </div>
        <div class="full" v-if="(changelogs == undefined || changelogs.length == 0)">
            <div class="full">
                <div class="label">Für den Mitarbeiter wurden noch keine Dokumente hochgeladen</div>
            </div>
        </div>
        
    </div>
    
    
</div>
</template>

<script>
import VueEventBus from '../../vue-event-bus';

export default {
    components: {
    },
    name: 'employeechangelogs',
    data() {
        return {
            loading: true,
            changelogs: [],
            files: [],

            reportIds: [],
            reports: [],

            authUsers: [],

            fields: [{
                key: "creationDate",
                label: "Datum",
                formatter: "creationDate",
                sortable: true,
                thClass: 'documentsTh',
                tdClass: 'documentsTd',
                thStyle: {
                    width: '240px !important'
                }
            }, {
                key: "editedFrom",
                label: "Geändert von",
                formatter: "documentType",
                sortable: false,
                thClass: 'documentsTh',
                tdClass: 'documentsTd',
                thStyle: {
                    width: '240px !important'
                }
            }, {
                key: "actions",
                label: "Änderungsmeldung PDF",
                sortable: false,
                thClass: 'documentsTh',
                tdClass: 'documentsTd',
                thStyle: {
                    width: '240px !important'
                }
            }],
        }
    },
    watch: {
        employee: function (val, oldVal){
            this.refresh();
        }
    },
    props: [
        "employee"
    ],
    computed: {
        filesFiltered(){
            let filtered = [];
            if(this.changelogs == null || this.changelogs == undefined) this.changelogs = [];
            this.changelogs.forEach((f) => {
                let creationDate = this.$helpers.getMomentFromUnix(f.timestamp);
                f.creationDate = creationDate.format("YYYY-MM-DD HH:mm");

                f.report = null;
                this.reports.forEach((r) => {
                    if(r.id == f.jobReportsId){
                        f.report = r;
                    }
                });

                f.editedFrom = "unbekannt";
                this.authUsers.forEach((a) => {
                    if(a.id == f.authuserId){
                        f.authUser = a;
                        f.editedFrom = a.name;
                    }
                });

                filtered.push(f);
            });
            return filtered;
        }
    },
    mounted(){
        this.refresh();
    },
    created: function () {
        //console.log("CREATED");
    },
    beforeDestroy: function(){
    },
    destroyed() {
    },
    methods: {
        refresh: function(){
            let view = this;
            view.getEmployeeChangelogs().then((response) => {
                view.changelogs = response.data.data.employeeChangelogs;
                view.authUsers = response.data.data.authUsers;

                this.reportIds = [];
                this.changelogs.forEach((f) => {
                    if(f.jobReportsId != null){
                        this.reportIds.push(f.jobReportsId);
                    }
                });

                view.getReports().then((response)=>{
                    view.reports = response.data.data;
                });
                
            }).catch(function (error){
            });
        },

        openFile(row){
            let view = this;
            let url = '/api/sec/report';
            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/report');
            url = this.$helpers.appendParam("ids", row.item.jobReportsId, url, '/api/sec/report');
            this.axios({
                method: 'get',
                headers: {
                    'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                },
                url: url,
                data: {employeeIds: [view.employee.id]}
            }).then((response) => {                
                let urlForFile = response.data.data[0].fileserverURL+'/api/file?id='+response.data.data[0].fileserverID+'&accessToken='+response.data.data[0].fileserverAccessToken;
                window.open(urlForFile, '_blank');
            }).catch(function (error){
                console.log(error);
            });


/*
            let url = row.item.url+'/api/file?id='+row.item.fileId+'&accessToken='+row.item.accessToken;
            window.open(url, '_blank');*/
        },
        getEmployeeChangelogs: function(){
            let view = this;
            let url = '/api/sec/employee/changelog';
            
            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employee/changelog');
            
            return this.axios({
                method: 'post',
                headers: {
                    'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                },
                url: url,
                data: {employeeIds: [view.employee.id]}
            });
        },

        getReports: function(){
            let view = this;
            let url = '/api/sec/report';
            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/report');
            url = this.$helpers.appendParam("reportType", "EMPLOYEECHANGELOG", url, '/api/sec/report');
            url = this.$helpers.appendParam("ids", view.reportIds.join(), url, '/api/sec/report');
            
            return this.axios({
                method: 'get',
                headers: {
                    'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                },
                url: url,
                data: {employeeIds: [view.employee.id]}
            });
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.dropdown-search.filters {
    height: 18px !important;
    margin-top: -4px;
    display: block;
}
/*
.content *:not(.main-settings)>.half {
    height: 105px;
}
*/
</style>