<template>
<div>
    <div class="full">
        <div class="full">
            <div class="mainheader">
                <div class="half">
                    ÖGK Meldungen
                </div>
            </div>
        </div>
        <div v-if="loading" class="loading-md bg">
          <i class="fas fa-spinner inner"></i>
          <div v-if="!$helpers.isEmpty(loadingText)" class="loading-text" >
            {{ loadingText }}
          </div>
        </div>
        <div v-if="!loading">
            <div v-if="!getCreatingEldaRequest()" class="full split-bottom">
                <div v-if="companySettings && companySettings.socialInsuranceRegistrationMode != 0 && employee.socialInsuranceRegistered == 0">
                  <div class="alert alert-danger" role="alert">
                    <strong>{{employee.firstName}} {{employee.lastName}}</strong> ist NICHT bei der ÖGK angemeldet!
                  </div>
                  <!-- <div v-if="employee.socialInsuranceRegistered == 1" class="alert alert-success" role="alert">
                      <strong>{{employee.firstName}} {{employee.lastName}}</strong> ist bei der ÖGK angemeldet!
                  </div> -->
                </div>
                <div>
                  <div class="header">
                      <div class="label">An/Abmeldungen</div>
                      <div class="header-right">
                      <div v-tooltip="'Neue Anmeldung'" class="button small" v-on:click="startRegister">
                          <div class="label">
                              Neue Anmeldung
                          </div>
                      </div>
                      </div>
                  </div>
                  <table class="table table-striped table-borderless">
                      <tr>
                          <th class="documentsTh">Anmeldedatum</th>
                          <th class="documentsTh">Abmeldedatum</th>
                          <th class="documentsTh">Status Dokument</th>
                          <th class="documentsTh"></th>
                      </tr>
                      <tr v-for="row in getRegistrations" :key="'employeeSocialInsuranceRegistrationId_'+row.id">

                          <td class="documentsTd">{{ $helpers.extractDateString(row.registrationDate, 'DD.MM.YYYY') }}</td>

                          <td class="documentsTd" v-if="(row.deregistrationDate)">{{ $helpers.extractDateString(row.deregistrationDate, 'DD.MM.YYYY') }}</td>
                          <td v-else/>


                          <!-- <td class="documentsTd" v-if="(row.registrationDocument)">
                              <buttonc label="Öffnen" type="button" v-on:click="openDocument(row)"/>
                          </td> -->

                          <td class="documentsTd" v-if="row.elda">
                              {{ getEldaStatus(row.status, row.api_state) }}
                          </td>
                          <td class="documentsTd" v-else-if="!(row.registrationDocument)">
                              <FileUploadBoxCopy @upload-success="onUploadSuccess" :employee="employee" :sirid="row.id" ></FileUploadBoxCopy>
                          </td>
                          <td class="documentsTd" v-else-if="row.registrationDocument && !authUsersMap[row.registrationDocumentConfirmedBy]">
                              Dokument nicht bestätigt
                          </td>
                          <td class="documentsTd" v-else>
                              Dokument bestätigt
                          </td>

                          <td class="documentsTd">
                              <buttonc v-if="row.registrationDocument && !authUsersMap[row.registrationDocumentConfirmedBy]" v-tooltip="'Dokument bestätigen'" icon="fa-file-check" type="button" v-on:click="confirmDocument(row.id)"/>
                              <buttonc v-if="row.registrationDocument" v-tooltip="'Dokument öffnen'" icon="fa-file" v-on:click="openDocument(row)"/>
                              <buttonc v-else-if="row.elda && row.file" v-tooltip="'Dokument öffnen'" icon="fa-file" v-on:click="openEldaDocument(row)"/>
                              <buttonc v-if="!(row.deregistrationDate)" icon="fa-user-minus" v-tooltip="'Abmelden'" type="button" v-on:click="startDeregister(row.id)"/>
                              <buttonc v-if="!row.elda && (!row.registrationDocument || !authUsersMap[row.registrationDocumentConfirmedBy])" v-tooltip="'Anmeldung löschen'" icon="fa-trash" v-on:click="deleteSIR(row.id)"/>
                              <!-- <buttonc v-if="(!row.registrationDocument || !authUsersMap[row.registrationDocumentConfirmedBy])" v-tooltip="'Anmeldung löschen'" icon="fa-trash" v-on:click="deleteSIR(row.id)"/> -->
                          </td>
                      </tr>

                  </table>

                </div>

                <b-button
                  hidden
                  style="width: 242px; margin: 0px 0px;"
                  @click="setCreatingEldaRequest(true)"
                  squared
                  variant="success"
                >Elda Meldung durchführen</b-button>
            </div>

            <div v-if="getCreatingEldaRequest() && creatingEldaRequestPhase === 1" class="full split-bottom">
                <div class="header">
                    <div class="label">Elda Meldung durchführen</div>
                </div>
                <div v-for="superType in Object.keys(requestTypes)" style="margin-bottom:4px">
                  <div class="header" style="font-weight: 700; color: var(--col-label-default);">{{ superType }}</div>
                  <div v-for="key in Object.keys(requestTypes[superType])" style="margin-bottom:4px">
                    <b-button
                      style="width: 400px; margin: 0px 0px;"
                      @click="setEldaStateDataEntries(key, requestTypes[superType][key].label_de)"
                      squared
                      variant="outline-success"
                    >{{ requestTypes[superType][key].label_de }}</b-button>
                  </div>
                  <br>
                </div>
            </div>

            <div v-if="getCreatingEldaRequest() && creatingEldaRequestPhase === 2" class="full split-bottom">
                <div class="header">
                    <div class="label">{{ requestTitle }}</div>
                </div>
                <datafill :state=getEldaState() />
            </div>
        </div>
    </div>


</div>
</template>

<script>
import VueEventBus from '../../vue-event-bus';
import FileUploadBoxCopy from '@/components/employeecontext/FileUploadBoxCopy';
import axios from "axios";
import Datafill from "@/components/uicomponents/datafill";

export default {
    components: {
      Datafill,
        FileUploadBoxCopy
    },
    name: 'employeesocialinsuranceregistration',
    data() {
        return {
            loading: true,
            loadingText: "ÖGK Meldungen werden geladen...",
            registrations: [],

            authUsers: [],
            authUsersMap: {},

            countriesISO3: [],
            countriesKFZ: [],
            genders: [
              {
                value: "1",
                label: "männlich"
              },
              {
                value: "2",
                label: "weiblich"
              },
              {
                value: "3",
                label: "divers"
              },
              {
                value: "4",
                label: "offen"
              }
            ],

            requestTypes: {},
            requestFields: {},
            requestTitle: '',
            creatingEldaRequestPhase: 1,
            eldaStateDataEntries: {},

            employeeContractFromDate: undefined,
            employeeContragsCount: 0,

            eldaEmployerName: "",
            eldaEmployerPhoneNumber: "",
            eldaEmployerEmail: "",
            eldaEmployerInsurer: [],
            eldaCreator: [],

            register: '',
            deregister: '',
            companySettings: null,

            displayOnly: false
        }
    },
    watch: {
        employee: function (val, oldVal){
            this.refresh();
        },
        refreshSIR: function (val, oldVal){
            this.refresh();
        }
    },
    props: [
        "employee",
        "refreshSIR"
    ],
    computed: {
        getRegistrations() {
            let view = this;
            view.registrations.forEach((reg) => {
                //console.log(reg);
            });
            return view.registrations;
        }
    },
    mounted(){
        let view = this;

        let url = '/enum/country';
        this.api.get(url).then((response) => {
          for (let x in response.data) {
            let elem = response.data[x];
            this.countriesISO3.push({id: elem.id, value: elem.key, label: elem.valueGER});
            if(elem.kfz !== null) {
              this.countriesKFZ.push({id: elem.id, value: elem.kfz, label: elem.valueGER});
            }
          }
        });

        this.refresh();
    },
    created: function () {
        //console.log("CREATED");
        VueEventBus.$on("EmployeeSocialInsuranceRegistrationEvent:uploadDocument", this.documentUploaded);
        VueEventBus.$on("EmployeeSocialInsuranceRegistrationEvent:confirmDocument", this.documentConfirmed);
        VueEventBus.$on("EldaCallbackEvent:update", this.eldaCallBack);
        //VueEventBus.$on("JobEmployeeImportEvent:jobStarted", this.jobStarted);
        //VueEventBus.$on("JobEmployeeImportEvent:jobEnded", this.jobEnded);
    },
    beforeDestroy: function(){
        VueEventBus.$off("EmployeeSocialInsuranceRegistrationEvent:uploadDocument", null);
        VueEventBus.$off("EmployeeSocialInsuranceRegistrationEvent:confirmDocument", null);
        VueEventBus.$off("EldaCallbackEvent:update", null);
        //VueEventBus.$off("JobEmployeeImportEvent:jobStarted", null);
        //VueEventBus.$off("JobEmployeeImportEvent:jobEnded", null);
    },
    destroyed() {
    },
    methods: {
        refresh: function(){
          let view = this;
          view.loading = true;

          view.creatingEldaRequestPhase = 1;
          view.$parent.creatingEldaRequest = false;
          view.displayOnly = false;

          view.$helpers.GetCompanySettings().then(response => {
            console.log('Company settings:');
            console.log(response);
            this.companySettings = response.data[0];
          });
          view.getEmployeeSocialInsuranceRegistrations().then((response) => {
            view.registrations = [];

            let registrations = response.data.data.employeeSocialInsuranceRegistrations;
            view.authUsers = response.data.data.authUsers;

            let usersM = {};
            let data = view.authUsers;
            var i;
            for (i = 0; i < data.length; i++) {
              usersM[data[i].id] = data[i];
            }
            view.authUsersMap = usersM;

            let promises = [];
            registrations.forEach(reg => {
              promises.push(view.loadSocialInsuranceRegistrationEldaStatus(reg.id));
            });

            Promise.all(promises).then((responses) => {
              responses.forEach((response) => {

                if(response.data.data[0] !== undefined) {
                  let reg = registrations.find((reg) => reg.id === response.data.data[0].socialInsuranceRegistrationsId);

                  reg.elda = true;
                  reg.requestId = response.data.data[0].requestId;
                  reg.file = response.data.data[0].file;
                  reg.status = response.data.data[0].status;
                  reg.api_state = response.data.data[0].api_state;
                  reg.api_received = response.data.data[0].api_received;
                  reg.api_sent = response.data.data[0].api_sent;
                  reg.api_processed = response.data.data[0].api_processed;
                  reg.error = response.data.data[0].error;
                }
              });

              view.registrations = registrations;
              this.loading = false;
            });

          }).catch(function (error){
            console.log(error);
          });
          view.loadEldaEmployerSettings();
          view.loadEldaCreatorSettings();
          view.loadEldaRequestTypes();
          view.loadEmployeeContracts();
        },

        documentUploaded: function(){
            this.refresh();
        },
        documentConfirmed: function(){
            this.refresh();
        },
        openDocument(row){
            let docUrl = docUrl = 'https://fileserver1.meindienstplan.de/api/file?id='+row.registrationDocument.id+'&accessToken='+row.registrationDocument.accessToken;
            window.open(docUrl, '_blank').focus();
        },
        confirmDocument(sirid){
            let view = this;
            let url = '/api/sec/employee/socialinsuranceregistration/confirm';

            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employee/socialinsuranceregistration/confirm');

          console.log("sending");
            return this.axios({
                method: 'post',
                headers: {
                    'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                },
                url: url,
                data: {socialInsuranceRegistrationId: sirid}
            });

        },
        onUploadSuccess(file, response) {
            console.log(`Uploaded new file`);
        },
        startRegister(){
            let view = this;
            let initialValue = this.$helpers.getMoment();
            let dataEntries = [
                    {label: 'Anmeldedatum', key: 'registrationDate', hidden: false, type: 'date', value: initialValue }
            ];
            this.$helpers.OpenPopup({
                type: 'datafill',
                componentState: {
                    entries: dataEntries,

                    description: "",
                    selectCallback: function (item) {
                        console.log('Deregister date popup select callback');
                        console.log(item);
                        view.register = item.registrationDate.format("YYYY-MM-DD");
                        view.sendRegister()
                          .then((response) => {
                              view.refresh();
                          });
                    },
                },
                header: 'Mitarbeiter anmelden',
                remainAfterSelect: false,
                expanded: true,
                centered: true
            });
        },
        startDeregister(sirid){
            let view = this;
            let initialValue = this.$helpers.getMoment();
            let dataEntries = [
                    {label: 'Abmeldedatum', key: 'deregistrationDate', hidden: false, type: 'date', value: initialValue }
            ];
            this.$helpers.OpenPopup({
                type: 'datafill',
                componentState: {
                    entries: dataEntries,

                    description: "",
                    selectCallback: function (item) {
                        console.log('Deregister date popup select callback');
                        console.log(item);
                        view.deregister = item.deregistrationDate.format("YYYY-MM-DD");
                        view.sendDeregister(sirid);
                    },
                },
                header: 'Mitarbeiter abmelden',
                remainAfterSelect: false,
                expanded: true,
                centered: true
            });
        },
        askDeleteSIR(sirid){
            this.$helpers.OpenAlert({
                header: "Anmeldung löschen?",
                description: "Sind Sie sicher, dass Sie die Anmeldung löschen möchten? Dies kann NICHT rückgängig gemacht werden!",
                callbackAccept: ()=>{
                    this.deleteSIR(sirid);
                },
                acceptLabel: "Anmeldung löschen"
            });
        },
        deleteSIR(sirid){
            console.log('Deleting SIR ID: ' + sirid);
            let view = this;
            let url = '/api/sec/employee/socialinsuranceregistration/delete';

            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employee/socialinsuranceregistration/delete');

            return this.axios({
                method: 'delete',
                headers: {
                    'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                },
                url: url,
                data: {socialInsuranceRegistrationId: sirid}
            }).then((response) => {
              this.refresh();
            }).catch((error) => {
              console.log(error);
              this.refresh();
            });
        },
        getEmployeeSocialInsuranceRegistrations: function(){
            let view = this;
            let url = '/api/sec/employee/socialinsuranceregistration';

            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employee/socialinsuranceregistration');
            url = this.$helpers.appendParam("employeeId", view.employee.id, url, '/api/sec/employee/socialinsuranceregistration');

            return this.axios({
                method: 'get',
                headers: {
                    'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                },
                url: url
            });
        },
        sendDeregister: function(sirid){
            console.log('Sending deregistration for SIR ID: ' + sirid);
            console.log(this.deregister);
            let view = this;
            let url = '/api/sec/employee/socialinsuranceregistration/deregister';
            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employee/socialinsuranceregistration/deregister');
            this.axios({
                method: 'post',
                headers: {
                    'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                },
                url: url,
                data: {employeeId: view.employee.id,deregistrationDate: view.deregister,socialInsuranceRegistrationId: sirid}
            }).then((response) => {
                console.log(response);
                view.refresh();
            }).catch(function (error){
                console.log(error);
            });

        },
        sendRegister: function(){
            let view = this;
            let url = '/api/sec/employee/socialinsuranceregistration/register';
            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/employee/socialinsuranceregistration/register');

            return this.axios({
                method: 'post',
                headers: {
                    'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                },
                url: url,
                data: {employeeId: view.employee.id,registrationDate: view.register}
            }).catch(function (error){
                view.$helpers.error("Error", error.response.data);
            });
/*
            let url = row.item.url+'/api/file?id='+row.item.fileId+'&accessToken='+row.item.accessToken;
            window.open(url, '_blank');*/
        },
        async uploadFile(file, sirid) {

          const formData = new FormData();
          formData.append('file', file, file.name);
          formData.append('companyId', `${ this.$store.state.companyId }`);
          formData.append('uploadType', 'SOCIALINSURANCEREGISTRATION');
          formData.append('employeeId', this.employee.id);
          formData.append('socialInsuranceRegistrationId', sirid);

          try {
            const response = await axios.post('/api/sec/file/upload', formData, {
              headers: {
                Authorization: 'Bearer ' + this.$store.state.jwt,
                'Content-Type': 'multipart/form-data',
              },
            });
            console.log(response.data);
            this.refresh();
          } catch (error) {
            this.showErrorAlert(`Upload von ${ file.name } fehlgeschlagen: ${ error.message }`);
          }
        },
        urltoFile(url, filename, mimeType){
          return (fetch(url)
              .then(function(res){return res.arrayBuffer();})
              .then(function(buf){return new File([buf], filename,{type:mimeType});})
          );
        },


        /*
         * ELDA
         */

        // prepare form
        setEldaStateDataEntries(key, label) {
          let view = this;

          console.log(view.employee);

          view.eldaStateDataEntries = {};
          let tempEntries = [];
          Object.keys(view.requestFields[key]).forEach((field) => {
              let currentField = view.requestFields[key][field];
              let currentEntry = {key: field, label: currentField.label_de, type: currentField.type, required: currentField.required};

              let currentDate = this.$helpers.getMoment();
              let socialsecurity = view.employee.socialsecurity != null ? view.employee.socialsecurity : '0000';
              let svnumber = socialsecurity + '' + view.$helpers.extractDateString(view.employee.dob, 'DDMMYY');

              // prepare fields per key
              switch (field) {
                // creator data
                case 'creatorId':
                  if (view.eldaCreator.length > 0) {
                    currentEntry.type = "dropdownsearch";
                    currentField.items = [];
                    view.eldaCreator.forEach(creator => {
                      currentField.items.push({label: creator.name, value: creator.id});
                    });
                    if(view.eldaCreator.length === 1) {
                      currentEntry.disabled = true;
                      currentEntry.selectedItem = currentField.items[0];
                    }
                  } else {
                    currentEntry.disabled = true;
                    currentEntry.value = "Bitte erstellen Sie einen Eintrag in Ihren Elda Hersteller Einstellungen."
                  }
                  break;
                // employer data
                case 'dienstgeberName':
                  currentEntry.hidden = view.eldaEmployerName !== '';
                  currentEntry.value = view.eldaEmployerName;
                  break;
                case 'dienstgeberEmail':
                  currentEntry.hidden = view.eldaEmployerEmail !== '';
                  currentEntry.value = view.eldaEmployerEmail;
                  break;
                case 'dienstgeberTelefonnr':
                  currentEntry.hidden = view.eldaEmployerPhoneNumber !== '';
                  currentEntry.value = view.eldaEmployerPhoneNumber;
                  break;
                case 'beitragskontonummer':
                  // text if no settings available
                  // hidden if exactly one is available from settings
                  // computed by selected insurer if more that one is available from settings
                  if (view.eldaEmployerInsurer.length === 1) {
                    currentEntry.hidden = true;
                    currentEntry.value = view.eldaEmployerInsurer[0]['contribution_accountnumber'];
                  } else if (view.eldaEmployerInsurer.length > 1) {
                    currentEntry.disabled = true;
                    currentEntry.computed = (datafill, lastVal, entryBeforeUpdate) => {
                      let insurer = datafill.entries.find(entry => entry.key === 'zustaendigerVersicherungstraeger');
                      if (insurer.value !== undefined) {
                        entryBeforeUpdate.value = view.eldaEmployerInsurer.find(entry => entry.insurer === insurer.value).contribution_accountnumber;
                      }
                      return entryBeforeUpdate;
                    }
                  }
                  break;
                case 'zustaendigerVersicherungstraeger':
                  // any insurer selectable if no settings available
                  // hidden if exactly one is available from settings
                  // dropdown minimized to insurer configured in settings if more than 1 is available
                  if (view.eldaEmployerInsurer.length === 1) {
                    currentEntry.hidden = true;
                    currentEntry.type = 'text';
                    currentEntry.value = view.eldaEmployerInsurer[0]['insurer'];
                  } else if (view.eldaEmployerInsurer.length > 1) {
                    let tempItems = [];
                    Object.values(view.eldaEmployerInsurer).forEach((insurer) => {
                      tempItems.push(insurer.insurer);
                    });
                    currentField.items = currentField.items.filter(i => tempItems.includes(i.value));
                  }
                  break;
                case 'geburtsdatum':
                  currentEntry.value = view.employee.dob;
                  break;
                case 'versicherungsnummer':
                  currentEntry.value = svnumber;
                  break;
                case 'vorname':
                  currentEntry.value = view.employee.firstName;
                  break;
                case 'familienname':
                  currentEntry.value = view.employee.lastName;
                  break;
                case 'wohnortLand':
                  currentEntry.selectedItem = view.getKFZCountryById(view.employee.countryId);
                  break;
                case 'wohnortPlz':
                  currentEntry.value = view.employee.postalCode;
                  break;
                case 'wohnortOrt':
                  currentEntry.value = view.employee.city;
                  break;
                case 'wohnortStrasse':
                  currentEntry.value = view.employee.address;
                  break;
                case 'geschlecht':
                  currentEntry.selectedItem = view.getGenderById(view.employee.genderId);
                  break;
                case 'staatsangehoerigkeit':
                  currentEntry.selectedItem = view.getISO3CountryById(view.employee.countryId);
                  break;
                case 'anmeldedatum':
                  if (view.employeeContractFromDate === undefined || view.employeeContractFromDate == null) {
                    view.employeeContractFromDate = currentDate;
                  }
                  currentEntry.value = view.employeeContractFromDate;
                  break;
                case 'betrieblicheVorsorgeAB':
                  if (view.employeeContractFromDate === undefined || view.employeeContractFromDate == null) {
                    view.employeeContractFromDate = currentDate;
                  }
                  if (view.employeeContragsCount === 1) {
                    let date = new Date(view.employeeContractFromDate);
                    let newDate = new Date(new Date(view.employeeContractFromDate).setMonth(date.getMonth() + 1));
                    // get last day of previous month if rollover happened
                    if (((date.getMonth() + 1) % 12) !== newDate.getMonth()) {
                      newDate = new Date(newDate.setDate(0));
                    }
                    currentEntry.value = newDate.toISOString();
                  } else {
                    currentEntry.value = view.employeeContractFromDate;
                  }
                  break;
              }

              // prepare fields per type
              switch (currentEntry.type) {
                case 'date':
                  if (currentEntry.value === undefined || currentEntry.value == null) {
                    currentEntry.value = currentDate;
                  }
                  break;
                case 'value':
                  currentEntry.value = currentField.value;
                  currentEntry.hidden = true;
                  break;
                case 'dropdownsearch':
                  currentEntry.selectMultiple = false;
                  currentEntry.dropdownSearchState = {
                    toggled: false,
                    displayedItems: [],
                    input: '',
                    items: currentField.items,
                    placeholder: currentEntry.label + ' auswählen',
                    selectedItem: currentEntry.selectedItem,
                    styleLight: true,
                  };
                  break;
              }

              tempEntries.push(currentEntry);
          });
          view.eldaStateDataEntries = tempEntries;

          view.requestTitle = label;
          view.creatingEldaRequestPhase = 2
        },
        getEldaState(){
          let view = this;

          let dataEntries = view.eldaStateDataEntries;

          return {
            entries: dataEntries,
            errorCheck: [],
            description: "",
            displayOnly: view.displayOnly,
            positiveButtonLabel: "Mitarbeitermeldung absenden",
            selectCallback: function (item) {
              view.displayOnly = true;
              view.sendRegisterElda(item);
            },
          };
        },
        loadEmployeeContracts() {
          let view = this;
          let url = '/api/sec/proxy/md/employee/contracts';

          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/proxy/md/employee/contracts');
          url = this.$helpers.appendParam("employeeId", view.employee.id, url, '/api/sec/proxy/md/employee/contracts');

          view.employeeContractFromDate = undefined;
          view.employeeContragsCount = 0;

          this.axios({
            method: 'get',
            headers: {
              'AUTHORIZATION': "Bearer "+this.$store.state.jwt
            },
            url: url
          }).then(response => {
            if (response.data !== undefined && response.data[0] !== undefined) {
              view.employeeContractFromDate = response.data[0].from;
              view.employeeContragsCount = response.data.length;
            }
          });
        },

        // Registration
        validateRegisterElda: function(reg){
          let view = this;
          let url = '/api/sec/elda/register/validate';
          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/register/validate');

          return this.axios({
            method: 'post',
            headers: {
              'AUTHORIZATION': "Bearer "+this.$store.state.jwt
            },
            url: url,
            data: reg
          });
        },
        sendRegisterElda: function(reg){
          let view = this;
          let url = '/api/sec/elda/register';
          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/register');

          view.register = reg.anmeldedatum.format("YYYY-MM-DD");
          reg.anmeldedatum = reg.anmeldedatum.format('DD.MM.YYYY');

          view.validateRegisterElda(reg)
            .then((response) => {
              view.sendRegister()
                .then((response) => {

                  reg.socialInsuranceRegistrationsId = response.data.id;
                  view.sendSocialInsuranceRegistrationEldaStatus(reg.socialInsuranceRegistrationsId)
                    .then((response) => {

                      console.log(reg);

                      this.axios({
                        method: 'post',
                        headers: {
                          'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                        },
                        url: url,
                        data: reg
                      }).then((response) => {
                        console.log(response);

                        view.$parent.creatingEldaRequest = false;

                        view.refresh();
                      }).catch(function (error){
                        view.displayOnly = false;
                        console.log(error);
                      });

                    }).catch(function (error){
                      view.displayOnly = false;
                      console.log(error);
                    });

                });
            }).catch((error) => {
              console.log(error.response);
              view.displayOnly = false;
              // response has no data when there is an error on ML backend side
              if (error.response.data.data === undefined) {
                this.$helpers.error(
                  "Serverfehler",
                  "Bitte versuchen Sie die Anmeldung später erneut."
                );
              }
              try {
                // "cause" is an array for functional errors returned by elda
                error.response.data.data.cause.forEach((cause) => {
                  this.$helpers.error(
                    "Fehlerhafte Daten",
                    cause
                  );
                });
              } catch (e) {
                // catches errors in case "cause" was not an array (other errors like missing auth or other errors on elda service)
                this.$helpers.error(
                  "Fehlerhafte Daten",
                  error.response.data.data.cause
                );
              }
            });
        },

        // Request Status
        loadSocialInsuranceRegistrationEldaStatus: function(socialInsuranceRegistrationsId){
          let view = this;
          let url = '/api/sec/elda/request';

          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/request');
          url = this.$helpers.appendParam("socialInsuranceRegistrationsId", socialInsuranceRegistrationsId, url, '/api/sec/elda/request');

          return this.axios({
            method: 'get',
            headers: {
              'AUTHORIZATION': "Bearer "+this.$store.state.jwt
            },
            url: url
          });
        },
        sendSocialInsuranceRegistrationEldaStatus: function(socialInsuranceRegistrationsId){
          let view = this;
          let url = '/api/sec/elda/request';

          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/request');

          let payload = {
            socialInsuranceRegistrationsId: socialInsuranceRegistrationsId,
          }

          return this.axios({
            method: 'post',
            headers: {
              'AUTHORIZATION': "Bearer "+this.$store.state.jwt
            },
            url: url,
            data: payload
          }).catch(function (error){
            console.log(error);
          });
        },

        // Request Types & Fields
        loadEldaRequestTypes: function() {
          let view = this;
          let url = '/api/sec/elda/request/types';
          url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/request/types');

          this.axios({
            method: 'get',
            headers: {
              'AUTHORIZATION': "Bearer "+this.$store.state.jwt
            },
            url: url
          })
            .then((response) => {
                view.requestTypes = response.data;
                Object.keys(view.requestTypes).forEach((requestType) => {
                    view.loadEldaRequestFields(requestType)
                        .then((response) => {
                            view.requestFields[requestType] = response.data;
                        });
                });
                view.prepareEldaRequestTypes(response.data);
            });
        },
        prepareEldaRequestTypes(data) {
            let view = this;
            let requestTypes = {};

            let registrations = [];
            let registrationsCount = 0;
            let others = [];
            let othersCount = 0;

            Object.keys(data).forEach((requestType) => {
                if(requestType.startsWith("registration")) {
                    registrations[requestType] = data[requestType];
                    registrationsCount++;
                } else {
                    others[requestType] = data[requestType];
                    othersCount++;
                }
            });
            if(registrationsCount > 0) {
                requestTypes["Anmeldungen"] = registrations;
            }
            if(othersCount > 0) {
                requestTypes["Andere Meldungen"] = others;
            }
            view.requestTypes = requestTypes;
        },
        loadEldaRequestFields: function(requestType) {
            let view = this;
            let url = '/api/sec/elda/request/fields';
            url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/request/fields');
            url = this.$helpers.appendParam("requestType", requestType, url, '/api/sec/elda/request/fields');

            return this.axios({
              method: 'get',
              headers: {
                'AUTHORIZATION': "Bearer "+this.$store.state.jwt
              },
              url: url
            });
        },

        // Elda Settings
        loadEldaEmployerSettings: function () {
          let view = this;
          let url = "/api/sec/elda/settings/employer";
          const params = {
            companyId: this.$store.state.companyId,
          };
          this.axios({
            method: "get",
            headers: {
              AUTHORIZATION: "Bearer " + this.$store.state.jwt,
            },
            url: url,
            params,
          })
            .then((response) => {
              let data = response.data;

              console.log(data);

              if(data !== undefined) {
                view.eldaEmployerName = data.name;
                view.eldaEmployerPhoneNumber = data.phone_number;
                view.eldaEmployerEmail = data.email;
                view.eldaEmployerInsurer = data.insurer;
              } else {
                view.eldaEmployerName = '';
                view.eldaEmployerPhoneNumber = '';
                view.eldaEmployerEmail = '';
                view.eldaEmployerInsurer = [];
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        },
        loadEldaCreatorSettings: function () {
          let view = this;
          let url = "/api/sec/elda/settings/creator";
          const params = {
            companyId: this.$store.state.companyId,
          };
          this.axios({
            method: "get",
            headers: {
              AUTHORIZATION: "Bearer " + this.$store.state.jwt,
            },
            url: url,
            params,
          })
            .then((response) => {
              let data = response.data;

              console.log(data);

              if(data !== undefined) {
                view.eldaCreator = data;
              } else {
                view.eldaCreator = [];
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        },

        // Elda Print Service
        printEldaDocument(reqId, order = "protocol,employer,employee"){
        let view = this;
        let url = '/api/sec/elda/print';

        url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/elda/print');
        url = this.$helpers.appendParam("reqId", reqId, url, '/api/sec/elda/print');
        url = this.$helpers.appendParam("order", order, url, '/api/sec/elda/print');

        return this.axios({
          method: 'get',
          headers: {
            'AUTHORIZATION': "Bearer "+this.$store.state.jwt
          },
          url: url
        });
      },

        // Helper
        eldaCallBack: function(e){
          console.log("RECEIVED EVENT EldaCallbackEvent:update");
          this.refresh();
        },
        openEldaDocument(row){
          this.printEldaDocument(row.requestId)
            .then((response) => {
              let docUrl = 'data:application/pdf;base64,' + encodeURI(response.data.pdf);

              this.urltoFile('data:application/pdf;base64,' + encodeURI(response.data.pdf), 'socialinsurance.pdf','application/pdf')
                .then(function(file){
                  window.open(URL.createObjectURL(file), '_blank');
                });
            });
        },
        getEldaStatus(status, api_state) {
            switch (api_state) {
                case "NEW": return "neu";
                case "RUNNING": return "in Bearbeitung";
                case "SENT": return "gesendet";
                case "FAILED": return "Service steht nicht zur Verfügung.";
                case "PROCESSED":
                default:
                    switch (status) {
                        case "uebernommen": return "Meldung übernommen";
                        case "nicht_uebernommen": return "Meldung nicht übernommen";
                        case "teilweise_uebernommen": return "Meldung teilweise übernommen";
                        default: return "Status unbekannt.";
                    }
            }
        },
        getGenderById(genderId) {
          let selected = {};
          this.genders.forEach(function (item, index) {
            if (item.value == genderId) {
              selected.value = item.value;
              selected.label = item.label;
            }
          });
          return selected;
        },
        getISO3CountryById(countryId) {
          let selected = {};
          this.countriesISO3.forEach(function (item, index) {
            if (item.id == countryId) {
              selected.id = item.id;
              selected.value = item.value;
              selected.label = item.label;
            }
          });
          return selected;
        },
        getKFZCountryById(countryId) {
          let selected = {};
          this.countriesKFZ.forEach(function (item, index) {
            if (item.id == countryId) {
              selected.id = item.id;
              selected.value = item.value;
              selected.label = item.label;
            }
          });
          return selected;
        },
        setCreatingEldaRequest(bool) {
            this.creatingEldaRequestPhase = 1;
            this.$parent.creatingEldaRequest = bool;
        },
        getCreatingEldaRequest() {
            return this.$parent.creatingEldaRequest;
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.dropdown-search.filters {
  height: 18px !important;
  margin-top: -4px;
  display: block;
}

.btn-success {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
}
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
  box-shadow: 0 0 0 0.2rem rgba(162, 16, 57, 0.5);
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: var(--ml);
  border-color: rgba(162, 16, 57, 0.5);
}

.btn-outline-success {
  color: var(--ml);
  border-color: var(--ml);
}
.btn-outline-success:hover {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 16, 57, 0.5);
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: var(--ml);
  border-color: rgba(162, 16, 57, 0.5);
}

/*
.content *:not(.main-settings)>.half {
    height: 105px;
}
*/
</style>
