<template>
    <div class="grid">
        <div v-if="headerEnabled" class="header">
            <div class="row">
                <div class="item" v-bind:class="[item.label.split(' ').join('')]"  v-for="(item, i) in header" :key="i" v-on:click='' v-if="item.enabled" v-bind:style="{width: item.width}" v-on:click="Sort(item)">
                    <div class="label">{{item.label}}</div>
                    <i class="fas fa-angle-down" v-if="currentSort !== '' && currentSort === item.label && !sortOrder"></i>
                    <i class="fas fa-angle-up" v-if="currentSort !== '' && currentSort === item.label && sortOrder"></i>
                </div>
            </div>
        </div>
        <div class="data">
            <div class="row" v-bind:class="[item.selected ? 'selected': '', item.metadata ? item.metadata.severity : '', item.selectedForUnhide ? 'selectedForUnhide' : '', item.selectedForHide ? 'selectedForHide' : '']"  v-for="(item, i) in data" :key="i" v-on:click='SelectRow(item)'>
                <div class="item" v-bind:class="[itemHeader.label.split(' ').join('')]" v-for="(itemHeader, i) in header" :key="i" v-if="itemHeader.enabled"  v-on:click='SelectItem(item, itemHeader)' v-bind:style="{width: itemHeader.width}">
                    <div v-if="itemHeader.type === 'text'" class="label">{{itemHeader.map(item)}}</div>
                    <div v-if="itemHeader.type === 'code'" class="code" v-on:click="ToggleClass($event, 'edit')"><div class="label">****</div><input v-model="item.digit_code" v-on:keydown.enter="OnEnter(itemHeader, item);ToggleClassParent($event,'edit')" maxlength="4"/></div>
                    <div v-if="itemHeader.type === 'bool' && itemHeader.map(item)" class="checkbox true"><i class="fas fa-check-circle"></i></div>
                    <div v-if="itemHeader.type === 'bool' && !itemHeader.map(item)" class="checkbox false"><i class="fas fa-circle"></i></div>
                    <div v-if="itemHeader.type === 'icon'" v-bind:class="{ 'grayscale': item.isHidden }" v-bind:style="{background: (itemHeader.description === 'Profile Picture' ? GetProfilePictureUrl(item, 'small', false) : itemHeader.description === 'MD Employee Picture'? GetMDPictureUrl(item) : GetPublicPictureUrl(item)), 'box-shadow': item.metadata && item.metadata.color? '0 0 0 2px ' + item.metadata.color : ''}" class="icon"></div>
                    <div v-if="itemHeader.type === 'fa-icon'" class="label"><i :class="'fas fa-' + item.metadata.icon"/></div>
                    <div v-if="itemHeader.type === 'date'" class="label">TT.MM.JJJJ</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    export default {
        name: "grid",
        data(){
            return{
                currentSort: '',
                sortOrder: true
            }
        },
        props:{
            header: Array,
            data: Array,
            OnSelect: Function,
            OnSelectRow: Function,
            headerEnabled: Boolean
        },
        updated() {
            for(var x in this.header)
            {
                if(this.header[x].dynamicResize)
                {
                    this.ResizeColumn(this.$el, '.' + this.header[x].label.split(' ').join(''));
                }
            }
        },
        computed: {
            ...mapGetters([
                'imgCacheTimestamp',
            ]),
        },
        methods: {
            GetProfilePictureUrl(employee, size = null, urlOnly = false){
              if (employee.employeePicture_fileId == null) {
                  var port = location.port;
                  var url = location.protocol + '//' + location.hostname + '/img/avatar_white.png';
                  if(port != "80" && port != "443"){
                      url = location.protocol + '//' + location.hostname + ':' + location.port + '/img/avatar_white.png';
                  }

                  if(urlOnly) return url;
                  return 'var(--contrast-1) no-repeat center/cover url(/img/avatar.png)';
              } else {
                  var imgUrl = employee.employeePicture_server + "/api/file?id=" + employee.employeePicture_fileId + "&accessToken=" + employee.employeePicture_accessToken + '&s=' + this.imgCacheTimestamp;
                  if (size) imgUrl = imgUrl + '&thumb=' + size;
                  if(urlOnly) return imgUrl;
                  return 'var(--contrast-1) no-repeat center/cover  url(' + imgUrl + ')';
              }
            },
            GetPublicPictureUrl(employee){
                if(!employee.firstname || !employee.employeePicture_fileId) return '#cccccc';
                var imgUrl = employee.employeePicture_server + "/api/file?id=" + employee.employeePicture_fileId + "&accessToken=" + employee.employeePicture_accessToken + '&s=' + this.imgCacheTimestamp;
                return '#cccccc no-repeat center/cover  url(https://fileserver1.meindienstplan.de/api/file?id='+employee.public_picture_storageServerId+'&accessToken='+employee.public_picture_storageServerToken + '&time='+this.imgCacheTimestamp+')';
            },
            GetMDPictureUrl(employee){
                if(!employee.employeePicture) return '#cccccc';
                var pic = employee.employeePicture;
                return '#cccccc no-repeat center/cover  url(' + pic.server + '/api/file?id=' + pic.fileId + '&accessToken=' + pic.accessToken + '&thumb=small' + '&time='+this.imgCacheTimestamp+')';
            },
            ResizeColumn(gridEl, className){

                var listColumn = gridEl.querySelectorAll(className);
                var list = gridEl.querySelectorAll(className + '> *');
                var longest = 0;
                var padding = 0;
                for(var x in list) {

                    if (!isNaN(parseInt(x))) {
                        var temp = list[x].getBoundingClientRect().width;
                        if(temp > longest) longest = temp;
                    }
                }
                for(var x in listColumn) {
                    if (!isNaN(parseInt(x))) {
                        if(parseInt(x) === 0) padding = parseInt(window.getComputedStyle(listColumn[x], null).getPropertyValue('padding-left').split('px')[0]) * 2;
                        listColumn[x].style.width = (longest + padding) + 'px';
                    }
                }
            },
            OnEnter(itemHeader, item){
                if(itemHeader.OnEnter !== null) {
                    itemHeader.OnEnter(item);
                }
            },
            ToggleClass($event, className){
                var list = document.querySelectorAll('.item .code');
                for(var x in list)
                    if(!isNaN(parseInt(x))) list[x].classList.remove('edit');
                $event.currentTarget.classList.toggle(className);
                //$event.currentTarget.childNodes[1].value = '';
                $event.currentTarget.childNodes[1].focus();
            },
            ToggleClassParent($event, className){
                $event.currentTarget.parentNode.classList.toggle(className);
            },
            SelectRow(item){
                if(this.OnSelectRow) this.OnSelectRow(item);
                this.$forceUpdate();
            },
            SelectItem(item, itemHeader){
                if(itemHeader.OnClick) {
                    itemHeader.OnClick(item);
                    //return;

                }
                if(this.OnSelect) this.OnSelect(item);
                this.$forceUpdate();
            },
            GetLabel(header, item){

            },
            Sort(headerItem)
            {
                this.currentSort = headerItem.label;
                this.sortOrder = !this.sortOrder;
                if(headerItem.type === 'text') {
                    if (this.sortOrder) this.data.sort((a, b) => headerItem.map(a) > headerItem.map(b) ? 1 : -1);
                    if (!this.sortOrder) this.data.sort((a, b) => headerItem.map(a) < headerItem.map(b) ? 1 : -1);
                }
                if(headerItem.type === 'bool') {
                    if (this.sortOrder) this.data.sort((a, b) => headerItem.map(a) > headerItem.map(b) ? 1 : -1);
                    if (!this.sortOrder) this.data.sort((a, b) => headerItem.map(a) < headerItem.map(b) ? 1 : -1);
                }
                if(headerItem.type === 'icon') {
                    if (this.sortOrder) this.data.sort((a, b) => headerItem.map(a) > headerItem.map(b) ? 1 : -1);
                    if (!this.sortOrder) this.data.sort((a, b) => headerItem.map(a) < headerItem.map(b) ? 1 : -1);
                }
                this.$forceUpdate();
            }
        }
    }
</script>

<style scoped>
    .grid{
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        display: inline-block;

    }

    .grid .header{
        position: relative;
        display: inline-block;
    }

    .grid .data{
        position: relative;
        display: inline-block;
    }

    .grid .row{
        text-align: center;
        position: relative;
        display: block;
        flex-wrap: initial;
        margin: 0;
        transition: 0.2s;
        overflow-x: visible;
        white-space: nowrap;
    }

    .grid  .row .item .icon{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border-radius: 100px;
        background: var(--constrast-3);
    }
    .row .danger *{
        color: #dc3545;
    }
    .row .warning *{
        color: #ffc107;
    }

    .row .info * {
        color: #17a2b8;
    }
    /* case 'info':
                    color = '#17a2b8';
                    icon = 'info-circle';
                    break;
                case 'warning':
                    color = '#ffc107';
                    icon = 'exclamation-circle';
                    break;
                case 'danger':
                    color = '#dc3545';
                    icon = 'exclamation';
                    break; */
    .grid .row:hover  .icon{
        background: #ffffff;
    }
    .grid .row:nth-child(odd){
        background: var(--contrast-1);
    }
    .grid *:not(.header) .row.selected{
      background: var(--ml);
    }
    .grid *:not(.header) .row.selected *{
      color: #ffffff !important;
    }
    .grid *:not(.header) .row.selectedForUnhide, .grid *:not(.header) .row.selectedForHide{
        background: var(--contrast-4);
    }
    .grid *:not(.header) .row.selectedForUnhide *, .grid *:not(.header) .row.selectedForHide *{
        color: var(--bg);
    }
    .grid *:not(.header) .row.selectedForHide .item .icon{
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
    .grid *:not(.header) .row.selectedForUnhide .item .icon{
      -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
      filter: grayscale(0%);
    }
    .grid *:not(.header) .row:hover{
      background: var(--ml);
    }
    .grid *:not(.header) .row:hover * {
      color: #ffffff !important;
    }

    .grayscale{
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }

    /* .grid *:not(.header) .row:hover *, .grid *:not(.header) .row.danger *{
        color: #ffffff !important;
    }

    .grid *:not(.header) .row:hover *, .grid *:not(.header) .row.warning *{
        color: #ffffff !important;
    } */

    .grid .row .item{
        display: inline-block;
        padding: 5px 10px;
        text-align: left;
        position: relative;
    }
    .grid .row .label{

    }
    .grid .row .checkbox{

    }
    .grid .row .checkbox.true{
        color: var(--ml);
    }
    .grid .row .checkbox.false{

        color: #808080 !important;
        background: none;
        box-shadow: none;
    }
    .grid .row .icon{

    }
    .grid .header .row{
        background: none;
        box-shadow:inset 0 -2px 0 -1px #808080, inset 0 2px  0 -1px #d9d9d9;
        padding: 5px 0;
    }
    .grid .header .row .item{
        cursor: pointer;
        box-shadow: 2px 0 0 -1px #cccccc;
    }
    .grid .header .row .item:last-child{
        box-shadow: none;
    }
    .grid .header .row .item .fas{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 5px;
        height: 15px;

    }
    .fas{
        transition: .2s;
        cursor: pointer;
    }

</style>
