<template>
  <div>
    <div class="full">
      <div class="full">
        <div class="mainheader">
          <div class="half">
            Arbeitsvertrag
          </div>
        </div>
      </div>

      <div v-if="loading" class="label left">
        <i class="fas fa-circle-notch fa-spin"></i> Bitte warten<br/>
        Dokumente werden geladen<br/>
      </div>

      <div v-if="employeeContract" class="full split-bottom">

        <div class="header">
          <div class="label">Aktiver Vertrag</div>
        </div>

        <div class="third left">
          <div class="full">
            <div class="third left">
              <div class="label">Beruf</div>
            </div>
            <div class="twothirds right">
              <div class="label data">{{ employeeContract.jobName }}</div>
            </div>
          </div>
          <div class="full split-bottom-tight">
            <div class="third left">
              <div class="label">Kategorie</div>
            </div>
            <div class="twothirds right">
              <div class="label data">{{ employeeCategoryMap[employeeContract.employeeCategory] }}</div>
            </div>
          </div>
          <div class="full split-bottom-tight">
            <div class="third left">
              <div class="label">Kostenstelle</div>
            </div>
            <div class="twothirds right">
              <div class="label data">{{ getStoreInfoString(employeeContract.storeId) }}</div>
            </div>
          </div>
          <div class="full">
            <div class="third left">
              <div class="label">Vertragstyp</div>
            </div>
            <div class="twothirds right">
              <div class="label data">{{ contractTypeMap[employeeContract.contractType] }}</div>
            </div>
          </div>
          <div class="full">
            <div class="third left">
              <div class="label">Kollektiv V.</div>
            </div>
            <div class="twothirds right">
              <div class="label data">{{
                  (employeeContract.collectiveContractId && allowedContractMap) ? (allowedContractMap[employeeContract.collectiveContractId] ? getCollectiveContractName(employeeContract.collectiveContractId) : 'Unerlaubter KV') : 'Kein KV'
                }}
              </div>
            </div>
          </div>
          <div class="full split-bottom">
            <div class="third left">
              <div class="label">Anstellungsart</div>
            </div>
            <div class="twothirds right">
              <div class="label data">{{ employmentTypeMap[employeeContract.employmentType] }}</div>
            </div>
          </div>
          <div class="full split-bottom">
            <div class="third left">
              <div class="label">Schwangerschaft</div>
            </div>
            <div class="twothirds right">
              <div class="label data">{{ employeeContract.pregnant ? "Ja" : "Nein" }}</div>
            </div>
          </div>
          <div class="full split-bottom" v-if="employeeContract.accPeriodExclusion">
            <div class="third left">
              <div class="label">Durchrechnung Ausnahme</div>
            </div>
            <div class="twothirds right">
              <div class="label data">{{ employeeContract.accPeriodExclusion ? "Ja" : "Nein" }}</div>
            </div>
          </div>

        </div>
        <div class="third mid">
          <div class="full">
            <div class="twothirds left">
              <div class="label">Beschäftigungsgruppe</div>
            </div>
            <div class="third right">
              <div class="label data">{{ employeeContract.employmentGroup }}</div>
            </div>
          </div>
          <div class="full">
            <div class="twothirds left">
              <div class="label">Vertragsbeginn</div>
            </div>
            <div class="third right">
              <div class="label data">{{ employeeContractEntryDate(employee, false) }}</div>
            </div>
          </div>
          <div class="full" v-if="hasContractChangeDate">
            <div class="twothirds left">
              <div class="label">Änderung gültig ab</div>
            </div>
            <div class="third right">
              <div class="label data">{{ $helpers.extractDateStringTZ(employeeContract.from, 'DD.MM.YYYY') }}</div>
            </div>
          </div>
          <div v-if="employeeContract.probationTo" class="full" v-bind:class="{'split-bottom': !employeeContract.deadlineTo && !employeeContract.to}">
            <div class="twothirds left">
              <div class="label">Probezeit bis</div>
            </div>
            <div class="third right">
              <div class="label data">{{ $helpers.extractDateStringTZ(employeeContract.probationTo, 'DD.MM.YYYY') }}</div>
            </div>
          </div>
          <div v-if="employeeContract.deadlineTo" class="full" v-bind:class="{'split-bottom': !employeeContract.to}">
            <div class="twothirds left">
              <div class="label">Befristungsende</div>
            </div>
            <div class="third right">
              <div class="label data">{{ $helpers.getMomentFromStringTZ(employeeContract.deadlineTo).add(-1,'days').format('DD.MM.YYYY') }}</div>
            </div>
          </div>
          <div v-if="employeeContract.to" class="full split-bottom-tight">
            <div class="twothirds left">
              <div class="label">Gültig bis</div>
            </div>
            <div class="third right">
              <div class="label data">
                {{ $helpers.getMomentFromStringTZ(employeeContract.to).add(-1, 'days').format('DD.MM.YYYY') }}
              </div>
            </div>
          </div>
          <div class="full">
            <div class="twothirds left">
              <div class="label">Wochenstunden</div>
            </div>
            <div class="third right">
              <div class="label data">{{ minutesToHours(employeeContract.weekTimeRegular) }}</div>
            </div>
          </div>
          <div class="full">
            <div class="twothirds left">
              <div class="label">Tage/Woche</div>
            </div>
            <div class="third right">
              <div class="label data">{{ employeeContract.weekWorkDaysRegular }}</div>
            </div>
          </div>
          <div class="full">
            <div class="twothirds left">
              <div class="label">Normalarbeitstage</div>
            </div>
            <div class="third right">
              <div class="label data">{{ formatWeekDayArray(employeeContract.workDays) }}</div>
            </div>
          </div>
          <div class="full">
            <div class="twothirds left">
              <div class="label">Zusätzliche Monatsstunden</div>
            </div>
            <div class="third right">
              <div class="label data">{{ minutesToHours(employeeContract.additionalMonthWorkTime) }}</div>
            </div>
          </div>
          <div class="full">
            <div class="twothirds left">
              <div class="label">Überstunden Wochen Grenze</div>
            </div>
            <div class="third right">
              <div class="label data">{{ minutesToHours(employeeContract.weeklyOvertimeCutoff) }}</div>
            </div>
          </div>
          <div class="full">
            <div class="twothirds left">
              <div class="label">Überstunden Tages Grenze</div>
            </div>
            <div class="third right">
              <div class="label data">{{ minutesToHours(employeeContract.overtimeCutoff) }}</div>
            </div>
          </div>
          <div class="full">
            <div class="twothirds left">
              <div class="label">Vollzeit Wochenstunden Grenze</div>
            </div>
            <div class="third right">
              <div class="label data">{{ minutesToHours(employeeContract.fullTimeCutoff) }}</div>
            </div>
          </div>
          <div class="full">
            <div class="twothirds left">
              <div class="label">Vollzeit Tagesstunden Grenze</div>
            </div>
            <div class="third right">
              <div class="label data">{{ minutesToHours(employeeContract.dayFullTimeCutoff) }}</div>
            </div>
          </div>
          <div class="full split-bottom">
            <div class="twothirds left">
              <div class="label">Urlaubstage</div>
            </div>
            <div class="third right">
              <div class="label data">{{ minutesToDays(employeeContract.vacationTime) }}</div>
            </div>
          </div>
        </div>
        <!--<div class="third right">
          <div  class="full">
            <div class="full">
              <div class="half left">
                <div class="label">Dokument vorhanden</div>
              </div>
              <div class="half">
                <div class="button small">
                  <div class="label">
                    Vertragsdokument öffnen
                  </div>
                </div>
              </div>
            </div>
            <div class="full split-bottom-tight">
              <div class="half left">
                <div class="label">Vertrag hochgeladen</div>
              </div>
              <div class="half">
                <div class="label data"></div>
              </div>
            </div>

          </div>

        </div>-->

        <div class="full">
          <div class="third left">
            <div class="full">
              <div class="header">
                <div class="label">Zahlung</div>
              </div>
              <div class="third left">
                <div class="label">Zahlungstyp</div>
              </div>
              <div class="twothirds right">
                <div class="label data">{{ paymentTypeMap[employeeContract.paymentType] }}</div>
              </div>
            </div>
            <div class="full" v-if="employeeContract.paymentType == 2">
              <div class="third left">
                <div class="label">Lohntyp</div>
              </div>
              <div class="twothirds right">
                <div class="label data">{{ wageTypeMap[employeeContract.wageType] }}</div>
              </div>
            </div>
            <div class="full">
              <div class="third left">
                <div class="label">Zahlung</div>
              </div>
              <div class="twothirds right">
                <div class="label data">{{ employeeContract.payment / 100 }}</div>
              </div>
            </div>
            <div class="full" v-if="employeeContract.hasOwnProperty('extraPayment')">
              <div class="third left">
                <div class="label">Aufzahlung</div>
              </div>
              <div class="twothirds right">
                <div class="label data">{{ employeeContract.extraPayment / 100 }}</div>
              </div>
            </div>
            <div class="full">
              <div class="third left">
                <div class="label">Förderung</div>
              </div>
              <div class="twothirds right">
                <div class="label data">{{ employeeContract.assistancePayment / 100 }}</div>
              </div>
            </div>
          </div>
          <div class="third mid">
            <div class="full">
              <div class="header">
                <div class="label">Überstundenzuschläge</div>
              </div>
              <div class="half left">
                <div class="label">Bonus / Stunde</div>
              </div>
              <div class="half right">
                <div class="label data">{{ employeeContract.overtimeBonusHour / 100 }}</div>
              </div>
            </div>
            <div class="full">
              <div class="half left">
                <div class="label">Bonus pauschal</div>
              </div>
              <div class="half right">
                <div class="label data">{{ employeeContract.overtimeBonusShift / 100 }}</div>
              </div>
            </div>
          </div>
          <div class="third right" v-if="employeeContract.calculateNightWork">
            <div class="full">
              <div class="header">
                <div class="label">Nachtarbeit & Zuschläge</div>
              </div>
            </div>
            <div class="full">
              <div class="half left">
                <div class="label">Nachtarbeit ab</div>
              </div>
              <div class="half right">
                <div class="label data">{{ employeeContract.nightWorkFrom }}</div>
              </div>
            </div>
            <div class="full">
              <div class="half left">
                <div class="label">Nachtarbeit bis</div>
              </div>
              <div class="half right">
                <div class="label data">{{ employeeContract.nightWorkTo }}</div>
              </div>
            </div>
            <div class="full">
              <div class="half left">
                <div class="label">Nachtarbeit Berechnung</div>
              </div>
              <div class="half right">
                <div class="label data">{{ nightWorkModeMap[employeeContract.nightWorkMode] }}</div>
              </div>
            </div>
            <div class="full"
                 v-if="employeeContract.nightWorkMode == 1 || employeeContract.nightWorkMode == 4 || employeeContract.nightWorkMode == 5">
              <div class="half left">
                <div class="label">Nachtarbeit Grenze</div>
              </div>
              <div class="half right">
                <div class="label data">{{ (employeeContract.nightWorkCutoff).toFixed(2) }}%</div>
              </div>
            </div>
            <div class="full"
                 v-if="employeeContract.nightWorkMode == 1 || employeeContract.nightWorkMode == 3 || employeeContract.nightWorkMode == 5">
              <div class="half left">
                <div class="label">Bonus / Stunde</div>
              </div>
              <div class="half right">
                <div class="label data">{{ employeeContract.nightWorkBonusHour / 100 }}</div>
              </div>
            </div>
            <div class="full"
                 v-if="employeeContract.nightWorkMode == 1 || employeeContract.nightWorkMode == 2 || employeeContract.nightWorkMode == 4">
              <div class="half left">
                <div class="label">Bonus pauschal</div>
              </div>
              <div class="half right">
                <div class="label data">{{ employeeContract.nightWorkBonusShift / 100 }}</div>
              </div>
            </div>


          </div>
        </div>
        <div class="full" v-if="employeeContract.comment || employeeContract.exitReason">
          <div class="third left">
            <div class="full">
              <div class="header">
                <div class="label">Zusatzinfo</div>
              </div>
            </div>
            <div class="full" v-if="employeeContract.comment">
              <div class="third left">
                <div class="label">Kommentar</div>
              </div>
              <div class="twothirds right">
                <div class="label data">{{ employeeContract.comment }}</div>
              </div>
            </div>
            <div class="full" v-if="employeeContract.exitReason">
              <div class="third left">
                <div class="label">Austrittsgrund</div>
              </div>
              <div class="twothirds right">
                <div class="label data">{{
                    exitReasonMap[employeeContract.exitReason] ? exitReasonMap[employeeContract.exitReason] : employeeContract.exitReason
                  }}
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div v-else class="full split-bottom">
        <div class="twothirds left">
          <div v-if="!futureEmployeeContracts || futureEmployeeContracts.length == 0" class="full">
            <!-- <div class="header">
                <div class="label">Arbeitsvertrag anlegen</div>
                <div class="header-right">
                    <div class="button small right" style="margin-right: 0px;" @click="openNewContractWizard()">
                        <div class="label">
                            Vertrag erstellen
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="full">
              <div class="label">Kein aktiver Arbeitsvertrag vorhanden</div>
            </div>
          </div>
          <div v-else class="full">
            <div class="header">
              <div class="label">Aktiver Vertrag</div>
            </div>
            <div class="twothirds left">
              <div class="label">{{ 'Der Arbeitsvertrag beginnt am ' + formattedDate(upcomingContract.from) }}</div>
            </div>
            <div class="third right">
              <div class="button small right" style="margin-right: 0px;" @click="openDetails(upcomingContract)">
                <div class="label">
                  Details anzeigen
                </div>
              </div>
            </div>
          </div>
          <div class="full">
          </div>
        </div>
      </div>
      <div v-if="futureEmployeeContracts != undefined && futureEmployeeContracts.length > 0" class="full">
        <div class="header">
          <div class="label">Zukünftige Arbeitsverträge</div>
        </div>
        <b-table class="documents" striped hover :borderless="true" :outlined="false" :items="futureEmployeeContracts"
                 :fields="fieldsFuture">
          <template v-slot:cell(fileId)="row">
            <div style="text-align: left; margin-left: 20px;">
              <i class="fas fa-check-circle checkIcon coredata"
                 v-bind:class="{'true' : row.item.fileId, 'false' : !row.item.fileId}"></i>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="button small mright8" v-on:click="openDetails(row.item)">
              <div class="label">Details</div>
            </div>
<!--            <div class="button small mright8" v-on:click="openEditModal(row.item)">-->
<!--              <div class="label">Bearbeiten</div>-->
<!--            </div>-->
<!--            <div v-if="row.item.fileId" class="button small mright8" v-on:click="loadAndOpenContractFile(row.item)">-->
<!--              <div class="label">Dokument öffnen</div>-->
<!--            </div>-->
<!--            <div v-else class="button small mright8" v-on:click="selectFileForUpload(row.item)">-->
<!--              <div class="label">Dokument hochladen</div>-->
<!--            </div>-->
            <!--<div v-if="(!row.item.approved || !checkApprovalDate(row.item))" class="button small mright8" v-on:click="openApproveContractModal(row.item)">
                <div class="label">Bestätigen</div>
            </div>-->
<!--            <div v-if="row.item.approved && checkApprovalDate(row.item)" class="button small mright8 disabled">-->
<!--              <div class="label">Bestätigt</div>-->
<!--            </div>-->
<!--            <div v-if="!row.item.approved && isLastContract(row.item)" class="button small mright8"-->
<!--                 v-on:click="openDeleteContractModal(row.item)">-->
<!--              <div class="label">Löschen</div>-->
<!--            </div>-->
          </template>
        </b-table>
      </div>
      <div v-if="pastEmployeeContracts != undefined && pastEmployeeContracts.length > 0" class="full">
        <div class="header">
          <div class="label">Vergangene Arbeitsverträge</div>
        </div>
        <b-table class="documents" striped hover :borderless="true" :outlined="false" :items="pastEmployeeContracts"
                 :fields="fieldsPast">
          <template v-slot:cell(fileId)="row">
            <div style="text-align: left; margin-left: 20px;">
              <i class="fas fa-check-circle checkIcon coredata"
                 v-bind:class="{'true' : row.item.fileId, 'false' : !row.item.fileId}"></i>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="button small mright8" v-on:click="openDetails(row.item)">
              <div class="label">Details</div>
            </div>
<!--            <div class="button small mright8" v-on:click="openEditModal(row.item)">-->
<!--              <div class="label">Bearbeiten</div>-->
<!--            </div>-->
<!--            <div v-if="row.item.fileId" class="button small mright8" v-on:click="loadAndOpenContractFile(row.item)">-->
<!--              <div class="label">Dokument öffnen</div>-->
<!--            </div>-->
<!--            <div v-else class="button small mright8" v-on:click="selectFileForUpload(row.item)">-->
<!--              <div class="label">Dokument hochladen</div>-->
<!--            </div>-->
<!--            <div v-if="(!row.item.approved || !checkApprovalDate(row.item))" class="button small mright8"-->
<!--                 v-on:click="openApproveContractModal(row.item)">-->
<!--              <div class="label">Bestätigen</div>-->
<!--            </div>-->
<!--            <div v-if="row.item.approved" class="button small mright8"-->
<!--                 v-on:click="openUnapproveContractModal(row.item)">-->
<!--              <div class="label">Bestätigung aufheben</div>-->
<!--            </div>-->
<!--            <div v-if="!row.item.approved && isLastContract(row.item)" class="button small mright8"-->
<!--                 v-on:click="openDeleteContractModal(row.item)">-->
<!--              <div class="label">Löschen</div>-->
<!--            </div>-->
          </template>
        </b-table>
      </div>
    </div>

    <b-modal size="contractdetails" ref="modal-contractdetails" centered :hide-header="true" :hide-footer="true">
      <div class="wizard-popup">
        <b-row>
          <b-col>
            <div class="header main" style="background: none">
              <div class="label">Arbeitsvertrag Details</div>
            </div>
            <div class="close" v-on:click="hideModal('modal-contractdetails');">
              <i class="fas fa-times"></i>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="detailContract">
          <b-col style="margin-top: 40px">
            <div class="full split-bottom" v-if="parentContract(detailContract) != null">
              <div class="header">
                <div class="label">Vertragsänderung</div>
              </div>
              <div class="twothirds left">
                <div class="label">Setzt den seit {{ $helpers.extractDateStringTZ(parentContract(detailContract).from, 'DD.MM.YYYY') }} gültigen Vertrag fort.</div>
              </div>
              <div class="third">
                <div class="button small" @click="openDetails(parentContract(detailContract))">
                  <div class="label">
                    Vertrag anzeigen
                  </div>
                </div>
              </div>
            </div>
            <div class="full split-bottom" v-if="childContract(detailContract) != null">
              <div class="header">
                <div class="label">Vertragsänderung</div>
              </div>
              <div class="twothirds left">
                <div class="label">Wird ab {{ $helpers.extractDateStringTZ(childContract(detailContract).from, 'DD.MM.YYYY') }} durch eine Vertragsänderung ersetzt.</div>
              </div>
              <div class="third">
                <div class="button small" @click="openDetails(childContract(detailContract))">
                  <div class="label">
                    Vertrag anzeigen
                  </div>
                </div>
              </div>
            </div>
            <div class="full split-bottom">
              <div class="half left">
                <div class="header">
                  <div class="label">Vertrag</div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Beruf</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ detailContract.jobName }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Beschäftigungsgruppe</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ detailContract.employmentGroup }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Kategorie</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ employeeCategoryMap[detailContract.employeeCategory] }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Vertragstyp</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ contractTypeMap[detailContract.contractType] }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Anstellungsart</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ employmentTypeMap[detailContract.employmentType] }}</div>
                  </div>
                </div>
                <div class="full split-bottom">
                  <div class="half left">
                    <div class="label">Schwangerschaft</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ detailContract.pregnant ? "Ja" : "Nein" }}</div>
                  </div>
                </div>
                <div class="full split-bottom">
                    <div class="half left">
                        <div class="label">Durchrechnung Ausnahme</div>
                    </div>
                    <div class="half right">
                        <div class="label data">{{ detailContract.accPeriodExclusion ? "Ja" : "Nein" }}</div>
                    </div>
                </div>
                <div class="full split-bottom">
                  <div class="half left">
                    <div class="label">Kostenstelle</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ getStoreInfoString(detailContract.storeId) }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Gültig ab</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ $helpers.extractDateStringTZ(detailContract.from, 'DD.MM.YYYY') }}</div>
                  </div>
                </div>
                <div v-if="detailContract.probationTo" class="full" v-bind:class="{'split-bottom': !detailContract.to && !detailContract.deadlineTo}">
                  <div class="half left">
                    <div class="label">Probezeit bis</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ $helpers.getMomentFromStringTZ(detailContract.probationTo).add(-1,'days').format('DD.MM.YYYY') }}</div>
                  </div>
                </div>
                <div v-if="detailContract.deadlineTo" class="full" v-bind:class="{'split-bottom': !detailContract.to}">
                  <div class="half left">
                    <div class="label">Befristungsende</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ $helpers.getMomentFromStringTZ(detailContract.deadlineTo).add(-1,'days').format('DD.MM.YYYY') }}</div>
                  </div>
                </div>
                <div v-if="detailContract.to" class="full split-bottom-tight">
                  <div class="half left">
                    <div class="label">Gültig bis</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ $helpers.getMomentFromStringTZ(detailContract.to).add(-1,'days').format('DD.MM.YYYY') }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Wochenstunden</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ minutesToHours(detailContract.weekTimeRegular) }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Zusätzliche Monatsstunden</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ minutesToHours(detailContract.additionalMonthWorkTime) }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Tage/Woche</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ detailContract.weekWorkDaysRegular }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Normalarbeitstage</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ formatWeekDayArray(detailContract.workDays) }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Überstunden Wochen Grenze</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ minutesToHours(detailContract.weeklyOvertimeCutoff) }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Überstunden Tages Grenze</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ minutesToHours(detailContract.overtimeCutoff) }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Vollzeit Wochenstunden Grenze</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ minutesToHours(detailContract.fullTimeCutoff) }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Vollzeit Tagesstunden Grenze</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ minutesToHours(detailContract.dayFullTimeCutoff) }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Urlaubstage</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ minutesToDays(detailContract.vacationTime) }}</div>
                  </div>
                </div>
              </div>
              <div class="half">
                <div class="full">
                  <div class="header">
                    <div class="label">Zahlung</div>
                  </div>
                  <div class="half left">
                    <div class="label">Zahlungstyp</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ paymentTypeMap[detailContract.paymentType] }}</div>
                  </div>
                </div>
                <div class="full" v-if="detailContract.paymentType == 2">
                  <div class="half left">
                    <div class="label">Lohntyp</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ wageTypeMap[detailContract.wageType] }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="half left">
                    <div class="label">Zahlung</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ detailContract.payment / 100 }}</div>
                  </div>
                </div>
                <div class="full" v-if="detailContract.hasOwnProperty('extraPayment')">
                  <div class="half left">
                    <div class="label">Aufzahlung</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ detailContract.extraPayment / 100 }}</div>
                  </div>
                </div>
                <div class="full split-bottom">
                  <div class="half left">
                    <div class="label">Förderung</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ detailContract.assistancePayment / 100 }}</div>
                  </div>
                </div>
                <div class="full">
                  <div class="header">
                    <div class="label">Überstundenzuschläge</div>
                  </div>
                  <div class="half left">
                    <div class="label">Bonus / Stunde</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ detailContract.overtimeBonusHour /100 }}</div>
                  </div>
                </div>
                <div class="full split-bottom">
                  <div class="half left">
                    <div class="label">Bonus pauschal</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ detailContract.overtimeBonusShift/100 }}</div>
                  </div>
                </div>
                <div class="full" v-if="detailContract.calculateNightWork">
                  <div class="header">
                    <div class="label">Nachtarbeit & Zuschläge</div>
                  </div>
                </div>

                <div class="full" v-if="detailContract.calculateNightWork">
                  <div class="half left">
                    <div class="label">Nachtarbeit ab</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ detailContract.nightWorkFrom }}</div>
                  </div>
                </div>
                <div class="full" v-if="detailContract.calculateNightWork">
                  <div class="half left">
                    <div class="label">Nachtarbeit bis</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ detailContract.nightWorkTo }}</div>
                  </div>
                </div>
                <div class="full" v-if="detailContract.calculateNightWork && (detailContract.nightWorkMode == 1 || detailContract.nightWorkMode == 3 || detailContract.nightWorkMode == 5 )">
                  <div class="half left">
                    <div class="label">Bonus / Stunde</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ detailContract.nightWorkBonusHour/100 }}</div>
                  </div>
                </div>
                <div class="full" v-if="detailContract.calculateNightWork && (detailContract.nightWorkMode == 1 || detailContract.nightWorkMode == 2 || detailContract.nightWorkMode == 4 )">
                  <div class="half left">
                    <div class="label">Bonus pauschal</div>
                  </div>
                  <div class="half right">
                    <div class="label data">{{ detailContract.nightWorkBonusShift/100 }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="full" v-if="detailContract.comment">
              <div class="header">
                <div class="label">Zusatzinfo</div>
              </div>
              <div class="third left">
                <div class="label">Kommentar</div>
              </div>
              <div class="twothirds right">
                <div class="label data">{{detailContract.comment }}</div>
              </div>
              <div class="third left">
                <div class="label">Austrittsgrund</div>
              </div>
              <div class="twothirds right">
                <div class="label data">{{exitReasonMap[detailContract.exitReason] ? exitReasonMap[detailContract.exitReason] : detailContract.exitReason }}</div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
  <!--
                          <table class="table">
                              <tr>
                                  <th>Store ID</th>
                                  <th>Job</th>
                                  <th>Tage pro Woche</th>
                                  <th>Stunden pro Woche</th>
                                  <th>Start</th>
                                  <th>Ende</th>
                                  <th>Bezahlung</th>
                                  <th>Urlaubstage</th>
                              </tr>

                              <tr v-for="(item, index) in contracts">
                                  <td>{{ item.storeId}}</td>
                                  <td>{{ item.jobName}}</td>
                                  <td>{{ item.weekWorkDaysRegular}}</td>
                                  <td>{{ (item.weekTimeRegular / 60) }}</td>
                                  <td>{{ item.from}}</td>
                                  <td>{{ item.to}}</td>
                                  <td>{{ (item.payment/100)}} €</td>
                                  <td>{{ (item.vacationTime/60/24)}}</td>
                              </tr>
                          </table>
                      </div>-->
</template>


<script>
import {
  mapState,
  mapGetters
} from 'vuex';
import button from '../uicomponents/button.vue';

export default {
  components: {button},
  name: 'employeecontracts',
  data() {
    return {
      loading: true,

      detailContract: null,
      fieldsPast: [
        {
          key: "icon",
          label: "",
          sortable: false,
          thClass: 'documentsTh',
          tdClass: 'documentsTd fileIconRed'
        }, {
          key: "jobName",
          label: "Beruf",
          sortable: false,
          thClass: 'documentsTh',
          tdClass: 'documentsTd'
        }, {
          key: "weekTimeRegular",
          label: "Wochenstunden",
          formatter: "formattedWeekTime",
          sortable: false,
          thClass: 'documentsTh',
          tdClass: 'documentsTd'
        }, {
          key: "additionalMonthWorkTime",
          label: "Zusätzliche Monatsstunden",
          formatter: "formattedWeekTime",
          sortable: false,
          thClass: 'documentsTh',
          tdClass: 'documentsTd'
        }, {
          key: "from",
          label: "Vertragsbeginn",
          formatter: "formattedDate",
          sortable: false,
          thClass: 'documentsTh',
          tdClass: 'documentsTd'
        }, {
          key: "to",
          label: "Vertragsende",
          formatter: "formattedEndDate",
          sortable: false,
          thClass: 'documentsTh',
          tdClass: 'documentsTd'
        // }, {
        //   key: "fileId",
        //   label: "Dokument",
        //   sortable: false,
        //   thClass: 'documentsTh',
        //   tdClass: 'documentsTd'
        }, {
          key: "actions",
          label: "",
          sortable: false,
          thClass: 'documentsTh',
          tdClass: 'documentsTd'
        }
      ],
      fieldsFuture: [
        {
          key: "icon",
          label: "",
          sortable: false,
          thClass: 'documentsTh',
          tdClass: 'documentsTd fileIconPlus'
        }, {
          key: "jobName",
          label: "Beruf",
          sortable: false,
          thClass: 'documentsTh',
          tdClass: 'documentsTd'
        }, {
          key: "weekTimeRegular",
          label: "Wochenstunden",
          formatter: "formattedWeekTime",
          sortable: false,
          thClass: 'documentsTh',
          tdClass: 'documentsTd'
        }, {
          key: "additionalMonthWorkTime",
          label: "Zusätzliche Monatsstunden",
          formatter: "formattedWeekTime",
          sortable: false,
          thClass: 'documentsTh',
          tdClass: 'documentsTd'
        }, {
          key: "from",
          label: "Vertragsbeginn",
          formatter: "formattedDate",
          sortable: false,
          thClass: 'documentsTh',
          tdClass: 'documentsTd'
        }, {
          key: "to",
          label: "Vertragsende",
          formatter: "formattedEndDate",
          sortable: false,
          thClass: 'documentsTh',
          tdClass: 'documentsTd'
        // }, {
        //   key: "fileId",
        //   label: "Dokument",
        //   sortable: false,
        //   thClass: 'documentsTh',
        //   tdClass: 'documentsTd'
        }, {
          key: "actions",
          label: "",
          sortable: false,
          thClass: 'documentsTh',
          tdClass: 'documentsTd'
        }
      ],

      employeeCategoryMap: {},
      contractTypeMap: {},
      employmentTypeMap: {},
      paymentTypeMap: {},
      wageTypeMap: {},
      nightWorkerTypeMap: {},
      nightWorkModeMap: {},
      exitReasonMap: {},
    }
  },
  created: function () {
    //console.log("CREATED");
  },
  mounted: function () {
    console.log("MOUNTED");
    this.setupUIDataMaps();
    if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
      this.refresh();
    }
  },
  computed: {
    ...mapGetters(['companyId', 'isSuperUser', 'externalServicesMap', 'storeSettings', 'uiDataMap']),
    employeeContract: {
      get: function () {
        return this.employeeContractForEmployee(this.employee);
      }
    },
    pastEmployeeContracts: {
      get: function () {
        return this.employeeContractsForEmployeeWithType(this.employee, 0);
      }
    },
    futureEmployeeContracts: {
      get: function () {
        return this.employeeContractsForEmployeeWithType(this.employee, 2);
      }
    },
    hasContractChangeDate: function (){
      let currentEntry = this.employeeContractEntryDate(this.employee,false);
      let currentContractFrom = this.$helpers.getMomentFromStringTZ(this.employeeContract.from);
      return currentEntry != currentContractFrom.format('DD.MM.YYYY');
    },
    dateDisplay: function () {
      return this.$helpers.getMomentTZ().format('YYYY-MM-DD');
    },
    headerButtons() {
      let view = this;

      let state = {
        buttons: []
      };

      return state;
    },
    timeZoneList() {
      let result = [];
      /*
      this.timeZones.forEach(i => {
          let item = {label: i, value: i};
          result.push(item);
      })
      */
      return result;
    },
    upcomingContract: {
      get: function () {
        var val;
        for (var i = 0; i < this.futureEmployeeContracts.length; i++) {
          var cur = this.futureEmployeeContracts[i];
          if (!val) {
            val = cur;
          } else {
            let valDate = new Date(val.from);
            let curDate = new Date(cur.from);
            if (curDate < valDate) val = cur;
          }
        }
        return val;
      }
    }
  },
  watch: {
    storeId: function (val, oldVal) {
      this.refresh();
    },
    employee: function (val, oldVal) {
      this.loading = true;
      this.refresh();
    },
    contracts: function (val, oldVal) {
      this.refresh();
    }
  },
  props: [
    "employee",
    "uiData",
    "collectiveContractMap",
    "allowedContractMap",
    "contracts",
    "stores"
  ],
  // methods that implement data logic.
  methods: {
    refresh: function (force = false) {
      this.loading = true;
      let view = this;

      view.loading = false;
    },
    formattedDate(str) {
      return str ? this.$helpers.getMomentFromStringTZ(str).format("DD.MM.YYYY") : '-';
    },
    formattedEndDate(str) {
      return str ? this.$helpers.getMomentFromStringTZ(str).add(-1, 'days').format("DD.MM.YYYY") : '-';
    },
    formattedWeekTime(mins) {
      return (mins / 60) + " Stunden"
    },
    formatWeekDayArray(array){
      let result = "";
      if(array){
        array.forEach(e => {
          if(e == 1){result+="Mo "}
          else if(e == 2){result+="Di "}
          else if(e == 3){result+="Mi "}
          else if(e == 4){result+="Do "}
          else if(e == 5){result+="Fr "}
          else if(e == 6){result+="Sa "}
          else if(e == 7){result+="So "}
        })
      }

      return result;
    },
    openDetails(c) {
      this.detailContract = c;
      this.showModal('modal-contractdetails');
    },
    contractWithId(contractId) {
      if (this.employeeContract && this.employeeContract.id == contractId) return this.employeeContract;
      var allContracts = this.pastEmployeeContracts.concat(this.futureEmployeeContracts);
      for (var i = 0; i < allContracts.length; i++) {
        if (allContracts[i].id == contractId) return allContracts[i];
      }
      return null;
    },
    parentContract(contract) {
      if (contract.parentId) return this.contractWithId(contract.parentId);
    },
    childContract(contract) {
      var parentId = contract.id;
      if (this.employeeContract && this.employeeContract.parentId == parentId) return this.employeeContract;
      var allContracts = this.pastEmployeeContracts.concat(this.futureEmployeeContracts);
      for (var i = 0; i < allContracts.length; i++) {
        var cur = allContracts[i];
        if (cur && cur.parentId == parentId) return cur;
      }
      return null;
    },
    employeeContractEntryDate(employee, first) {
      var firstFrom = '';
      if (employee.workEntryDate != null && first) {
        firstFrom = this.$helpers.getMomentFromStringTZ(employee.workEntryDate).format('DD.MM.YYYY');
      } else {
        var contract = this.employeeContractForEmployee(employee);
        var pastContracts = this.employeeContractsForEmployeeWithType(employee, 0);
        var futureContracts = this.employeeContractsForEmployeeWithType(employee, 2);


        if (contract != null) {
          firstFrom = this.$helpers.extractDateStringTZ(contract.from, 'DD.MM.YYYY');
        }
        var d;
        pastContracts.forEach((pc) => {
          var curFrom = this.$helpers.getMomentFromStringTZ(pc.from);
          if (d == null || curFrom < d) d = curFrom;
        });
        if (d != null) firstFrom = d.format('DD.MM.YYYY');
        if (first) {
          return firstFrom;
        } else {
          var from = '';

          let c = contract;
          if (c == null && futureContracts.length > 0) {
            var nextFrom;
            futureContracts.forEach((fc) => {
              var curFrom = this.$helpers.getMomentFromStringTZ(fc.from);
              if (nextFrom == null) {
                nextFrom = curFrom;
                c = fc;
              } else {
                if (curFrom < nextFrom) {
                  nextFrom = curFrom;
                  c = fc;
                }
              }
            });
          }
          if (c != null) {
            from = this.$helpers.getMomentFromStringTZ(c.from);
            let preceeding = this.getPreceedingContract(c, pastContracts);
            while (preceeding != null) {
              from = this.$helpers.getMomentFromStringTZ(preceeding.from);
              c = preceeding;
              preceeding = this.getPreceedingContract(c, pastContracts);
            }

            return from.format('DD.MM.YYYY');
          }
        }
      }

      return firstFrom;
    },
    getPreceedingContract(contract, otherContracts) {
      let from = this.$helpers.getMomentFromStringTZ(contract.from);
      let prec;
      otherContracts.forEach((pc) => {
        let to = this.$helpers.getMomentFromStringTZ(pc.to);
        let diff = this.$helpers.TimeDiff(from, to);
        if (diff == 0) {
          prec = pc;
        }
      });
      return prec;
    },
    employeeContractForEmployee(employee) {
      var contract;
      var keys = this.contracts !== undefined ? Object.keys(this.contracts) : [];
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        if (this.contracts[key].remoteId == employee.id) {
          if (this.contractType(this.contracts[key]) == 1) {
            contract = this.contracts[key];
          }
        }
      }
      return contract;
    },
    contractType(c) {
      var now = new Date();
      let fromDate = new Date(c.from);
      if (c.to) {
        let toDate = new Date(c.to);
        if (toDate < now) {
          return 0;
        }
      }
      if (fromDate < now) return 1;
      if (fromDate > now) return 2;
      return -1;
    },
    employeeContractsForEmployeeWithType(employee, type) {
      var c = [];
      var keys = this.contracts !== undefined ? Object.keys(this.contracts) : [];
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        if (this.contracts[key].remoteId == employee.id) {
          let contract = this.contracts[key];
          if (this.contractType(contract) == type) c.push(contract);
        }
      }
      return c;
    },
    getCollectiveContractName(id) {
      let allowedContract = this.allowedContractMap[id];
      let collectiveContract = this.collectiveContractMap[allowedContract.collectiveContractId];
      let name = collectiveContract.name + " " + (allowedContract.weekTimeRegular / 60).toFixed(2) + "h / " + allowedContract.weekWorkDaysRegular + "T";
      if (allowedContract.additionalMonthWorkTime > 0) {
        name += " " + (allowedContract.additionalMonthWorkTime / 60).toFixed(2) + " ZS";
      }
      return name;
    },
    minutesToHours(minutes) {
      return minutes / 60;
    },
    minutesToDays(minutes) {
      return minutes / 60 / 24;
    },
    setupUIDataMaps() {

      this.uiData.employeeCategories.forEach(item => this.employeeCategoryMap[item.id] = item.label_de);
      this.uiData.contractTypes.forEach(item => this.contractTypeMap[item.id] = item.label_de);
      this.uiData.employmentTypes.forEach(item => this.employmentTypeMap[item.id] = item.label_de);
      this.uiData.paymentTypes.forEach(item => this.paymentTypeMap[item.id] = item.label_de);
      this.uiData.wageTypes.forEach(item => this.wageTypeMap[item.id] = item.label_de);
      this.uiData.nightWorkerTypes.forEach(item => this.nightWorkerTypeMap[item.id] = item.label_de);
      this.uiData.nightWorkModes.forEach(item => this.nightWorkModeMap[item.id] = item.label_de);
      this.uiData.exitReasons.forEach(item => this.exitReasonMap[item.id] = item.label_de);
    },
    getStoreInfoString(storeId) {
      let view = this;
      let store = view.stores.find((s) => s.id === storeId);
      if (store === null || store === undefined) {
        return "Unerlaubte Kostenstelle";
      }
      return store.name + (store.description ? ' / ' + store.description : '');
    },
    hideModal(ref) {
      this.$refs[ref].hide()
    },
    showModal(ref) {
      this.$refs[ref].show()
    },

  },

}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->

<style scoped>
a {
  color: var(--ml);
  cursor: pointer !important;
}

a:hover {
  color: var(--contrast-4);
  cursor: pointer !important;
}
</style>
