<template>
  <div>
    <div class="full">
      <div class="full">
        <div class="mainheader">
          <div class="half">
            Datenabfrage
            <span v-if="$parent.selectedInquirySet"
              >vom {{ selectedInquiry.created_atMoment.format("DD.MM.YYYY") }}</span
            >
          </div>
        </div>
      </div>

      <b-container fluid v-if="$parent.selectedInquirySet">
        <div class="full split-bottom">
          <br />
          <table class="table table-striped table-borderless">
            <tr>
              <th class="documentsTh">Bezeichnung</th>
              <th class="documentsTh">Derzeit gespeicherter Wert</th>
              <th class="documentsTh">Vom Mitarbeiter eingegeben</th>
              <th class="documentsTh">Änderung übernehmen</th>
            </tr>
            <tr v-if="selectedInquiry.requestFirstName">
              <td class="documentsTd">
                <strong>Vorname</strong>
              </td>
              <td class="documentsTd">
                {{ employee.firstName }}
              </td>
              <td class="documentsTd">
                {{ selectedInquiry.firstName }}
              </td>
              <td class="documentsTd">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  v-model="selectedInquiry.checkFirstName"
                />
              </td>
            </tr>
            <tr v-if="selectedInquiry.requestLastName">
              <td class="documentsTd">
                <strong>Nachname</strong>
              </td>
              <td class="documentsTd">
                {{ employee.lastName }}
              </td>
              <td class="documentsTd">
                {{ selectedInquiry.lastName }}
              </td>
              <td class="documentsTd">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  v-model="selectedInquiry.checkLastName"
                />
              </td>
            </tr>
            <tr
              v-if="selectedInquiry.requestFirstName || selectedInquiry.requestLastName"
            >
              <td colspan="4"></td>
            </tr>
            <tr
              v-if="selectedInquiry.requestFirstName || selectedInquiry.requestLastName"
              style="border-top: 1px solid #888"
            >
              <td colspan="4"></td>
            </tr>

            <tr v-if="selectedInquiry.requestDob">
              <td class="documentsTd">Geburtsdatum</td>
              <td class="documentsTd">
                {{ employee.dob }}
              </td>
              <td class="documentsTd">
                {{ selectedInquiry.dob }}
              </td>
              <td class="documentsTd">
                <input type="checkbox" style="cursor: pointer" />
              </td>
            </tr>
            <tr v-if="selectedInquiry.requestSocialsecurity">
              <td class="documentsTd">Sozialversicherungsnummer</td>
              <td class="documentsTd">
                {{ employee.socialsecurity }}
              </td>
              <td class="documentsTd">
                {{ selectedInquiry.socialsecurity }}
              </td>
              <td class="documentsTd">
                <input type="checkbox" style="cursor: pointer" />
              </td>
            </tr>

            <tr v-if="selectedInquiry.requestCitizenshipId">
              <td class="documentsTd">
                <strong>Staatsbürgerschaft</strong>
              </td>
              <td class="documentsTd">
                {{
                  enumCountry[employee.citizenshipId].valueGER != undefined
                    ? enumCountry[employee.citizenshipId].valueGER
                    : ""
                }}
              </td>
              <td class="documentsTd">
                {{ enumCountry[selectedInquiry.citizenshipId].valueGER }}
              </td>
              <td class="documentsTd">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  v-model="selectedInquiry.checkCitizenshipId"
                />
              </td>
            </tr>
            <tr v-if="selectedInquiry.requestCitizenTypeId">
              <td class="documentsTd">
                <strong>Bürgertyp</strong>
              </td>
              <td class="documentsTd">
                {{ enumCitizentype[employee.citizenTypeId].valueGER }}
              </td>
              <td class="documentsTd">
                {{ enumCitizentype[selectedInquiry.citizenTypeId].valueGER }}
              </td>
              <td class="documentsTd">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  v-model="selectedInquiry.checkCitizenTypeId"
                />
              </td>
            </tr>
            <tr v-if="selectedInquiry.requestCivilStatusId">
              <td class="documentsTd">
                <strong>Familienstand</strong>
              </td>
              <td class="documentsTd">
                {{ enumCivilStatus[employee.civilStatusId].valueGER }}
              </td>
              <td class="documentsTd">
                {{ enumCivilStatus[selectedInquiry.civilStatusId].valueGER }}
              </td>
              <td class="documentsTd">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  v-model="selectedInquiry.checkCivilStatusId"
                />
              </td>
            </tr>
            <tr
              v-if="
                selectedInquiry.requestCitizenshipId ||
                selectedInquiry.requestCitizenTypeId ||
                selectedInquiry.requestCivilStatusId
              "
            >
              <td colspan="4"></td>
            </tr>
            <tr
              v-if="
                selectedInquiry.requestCitizenshipId ||
                selectedInquiry.requestCitizenTypeId ||
                selectedInquiry.requestCivilStatusId
              "
              style="border-top: 1px solid #888"
            >
              <td colspan="4"></td>
            </tr>

            <tr v-if="selectedInquiry.requestAddress">
              <td class="documentsTd">
                <strong>Adresse</strong>
              </td>
              <td class="documentsTd">
                {{ employee.address }}
              </td>
              <td class="documentsTd">
                {{ selectedInquiry.address }}
              </td>
              <td class="documentsTd">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  v-model="selectedInquiry.checkAddress"
                />
              </td>
            </tr>
            <tr v-if="selectedInquiry.requestPostalCode">
              <td class="documentsTd">
                <strong>Postleitzahl</strong>
              </td>
              <td class="documentsTd">
                {{ employee.postalCode }}
              </td>
              <td class="documentsTd">
                {{ selectedInquiry.postalCode }}
              </td>
              <td class="documentsTd">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  v-model="selectedInquiry.checkPostalCode"
                />
              </td>
            </tr>
            <tr v-if="selectedInquiry.requestCity">
              <td class="documentsTd">
                <strong>Stadt</strong>
              </td>
              <td class="documentsTd">
                {{ employee.city }}
              </td>
              <td class="documentsTd">
                {{ selectedInquiry.city }}
              </td>
              <td class="documentsTd">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  v-model="selectedInquiry.checkCity"
                />
              </td>
            </tr>

            <tr v-if="selectedInquiry.requestCountryId">
              <td class="documentsTd">
                <strong>Land</strong>
              </td>
              <td class="documentsTd">
                {{ enumCountry[employee.countryId].valueGER }}
              </td>
              <td class="documentsTd">
                {{ enumCountry[selectedInquiry.countryId].valueGER }}
              </td>
              <td class="documentsTd">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  v-model="selectedInquiry.checkCountryId"
                />
              </td>
            </tr>

            <tr
              v-if="
                selectedInquiry.requestAddress ||
                selectedInquiry.requestPostalCode ||
                selectedInquiry.requestCity ||
                selectedInquiry.requestCountryId
              "
            >
              <td colspan="4"></td>
            </tr>
            <tr
              v-if="
                selectedInquiry.requestAddress ||
                selectedInquiry.requestPostalCode ||
                selectedInquiry.requestCity ||
                selectedInquiry.requestCountryId
              "
              style="border-top: 1px solid #888"
            >
              <td colspan="4"></td>
            </tr>

            <tr v-if="selectedInquiry.requestHomephone">
              <td class="documentsTd">
                <strong>Festnetz Telefon</strong>
              </td>
              <td class="documentsTd">
                {{ employee.homephone }}
              </td>
              <td class="documentsTd">
                {{ selectedInquiry.homephone }}
              </td>
              <td class="documentsTd">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  v-model="selectedInquiry.checkHomephone"
                />
              </td>
            </tr>
            <tr v-if="selectedInquiry.requestMobilephone">
              <td class="documentsTd">
                <strong>Mobil Telefon</strong>
              </td>
              <td class="documentsTd">
                {{ employee.mobilephone }}
              </td>
              <td class="documentsTd">
                {{ selectedInquiry.mobilephone }}
              </td>
              <td class="documentsTd">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  v-model="selectedInquiry.checkMobilephone"
                />
              </td>
            </tr>
            <tr
              v-if="
                selectedInquiry.requestHomephone || selectedInquiry.requestMobilephone
              "
            >
              <td colspan="4"></td>
            </tr>
            <tr
              v-if="
                selectedInquiry.requestHomephone || selectedInquiry.requestMobilephone
              "
              style="border-top: 1px solid #888"
            >
              <td colspan="4"></td>
            </tr>

            <tr v-if="selectedInquiry.requestBankAccountHolder">
              <td class="documentsTd">
                <strong>Konto Inhaber</strong>
              </td>
              <td class="documentsTd">
                {{ employee.bankAccountHolder }}
              </td>
              <td class="documentsTd">
                {{ selectedInquiry.bankAccountHolder }}
              </td>
              <td class="documentsTd">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  v-model="selectedInquiry.checkBankAccountHolder"
                />
              </td>
            </tr>
            <tr v-if="selectedInquiry.requestIban">
              <td class="documentsTd">
                <strong>IBAN</strong>
              </td>
              <td class="documentsTd">
                {{ employee.iban }}
              </td>
              <td class="documentsTd">
                {{ selectedInquiry.iban }}
              </td>
              <td class="documentsTd">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  v-model="selectedInquiry.checkIban"
                />
              </td>
            </tr>
            <tr v-if="selectedInquiry.requestBic">
              <td class="documentsTd">
                <strong>BIC</strong>
              </td>
              <td class="documentsTd">
                {{ employee.bic }}
              </td>
              <td class="documentsTd">
                {{ selectedInquiry.bic }}
              </td>
              <td class="documentsTd">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  v-model="selectedInquiry.checkBic"
                />
              </td>
            </tr>
          </table>
          <br />
        </div>
      </b-container>

      <b-container fluid v-if="!$parent.selectedInquirySet">
        <div class="full split-bottom">
          <div class="label left" v-if="loading">
            <i class="fas fa-circle-notch fa-spin"></i> Bitte warten<br />
            Daten werden geladen<br />
          </div>

          <div class="label left" v-if="!loading">
            <table class="table table-striped table-borderless">
              <tr>
                <th class="documentsTh">Abgefragte Daten</th>
                <th class="documentsTh">Erstellt am</th>
                <th class="documentsTh">Erstellt von</th>
                <th class="documentsTh">Ausfüllbar bis</th>
                <th class="documentsTh">Status</th>
                <th class="documentsTh"></th>
              </tr>
              <tr v-for="inq in inquiries">
                <td class="documentsTd">
                  <buttonc
                    v-tooltip="'Abgefragte Daten öffnen'"
                    icon="fa-eye"
                    v-on:click="openRequestedPopup(inq)"
                  />
                </td>
                <td class="documentsTd">
                  {{ inq.created_atMoment.format("DD.MM.YYYY") }}
                </td>
                <td class="documentsTd">
                  {{ inq.createdFrom }}
                </td>
                <td class="documentsTd">
                  {{ inq.validTill }}
                </td>
                <td class="documentsTd">
                  {{ stateText(inq) }}
                </td>
                <td class="documentsTd">
                  <buttonc
                    v-if="inq.state == 1"
                    v-tooltip="'Abfrage öffnen'"
                    icon="fa-edit"
                    v-on:click="openInquiry(inq)"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </b-container>
    </div>

    <b-modal ref="modal-employee-datainquiry" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Abgefragte Daten</div>
        </div>
      </template>
      <b-container fluid>
        <b-row class="my-1" style="text-align: left">
          <div style="text-align: left; width: 100%; padding: 3px; font-size: 17px">
            <strong>Folgende Daten wurden abgefragt.</strong>
          </div>
          <br /><br />
          <div
            style="text-align: left; width: 50%; padding-left: 30px; margin-bottom: 15px"
          >
            <strong>Persönliche Daten</strong><br />
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestFirstName"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestFirstName"
                ></i>
              </div>

              <span v-bind:class="!datainquiry.requestFirstName ? 'text-muted' : ''"
                >Vorname</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestLastName"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestLastName"
                ></i>
              </div>

              <span v-bind:class="!datainquiry.requestLastName ? 'text-muted' : ''"
                >Nachname</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestCitizenshipId"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestCitizenshipId"
                ></i>
              </div>

              <span v-bind:class="!datainquiry.requestCitizenshipId ? 'text-muted' : ''"
                >Staatsbürgerschaft</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestCitizenTypeId"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestCitizenTypeId"
                ></i>
              </div>

              <span v-bind:class="!datainquiry.requestCitizenTypeId ? 'text-muted' : ''"
                >Bürgertyp</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestCivilStatusId"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestCivilStatusId"
                ></i>
              </div>

              <span v-bind:class="!datainquiry.requestCivilStatusId ? 'text-muted' : ''"
                >Familienstand</span
              >
            </div>
          </div>
          <div
            style="text-align: left; width: 50%; padding-left: 30px; margin-bottom: 15px"
          >
            <strong>Adressdaten</strong><br />

            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestAddress"
                ></i>
                <i class="fas fa-times text-muted" v-if="!datainquiry.requestAddress"></i>
              </div>

              <span v-bind:class="!datainquiry.requestAddress ? 'text-muted' : ''"
                >Anschrift</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestPostalCode"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestPostalCode"
                ></i>
              </div>

              <span v-bind:class="!datainquiry.requestPostalCode ? 'text-muted' : ''"
                >Postleitzahl</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i class="fas fa-check text-success" v-if="datainquiry.requestCity"></i>
                <i class="fas fa-times text-muted" v-if="!datainquiry.requestCity"></i>
              </div>

              <span v-bind:class="!datainquiry.requestCity ? 'text-muted' : ''"
                >Stadt</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestCountryId"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestCountryId"
                ></i>
              </div>

              <span v-bind:class="!datainquiry.requestCountryId ? 'text-muted' : ''"
                >Land</span
              >
            </div>
          </div>

          <div
            style="text-align: left; width: 50%; padding-left: 30px; margin-bottom: 15px"
          >
            <strong>Bankverbindung</strong>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestBankAccountHolder"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestBankAccountHolder"
                ></i>
              </div>

              <span
                v-bind:class="!datainquiry.requestBankAccountHolder ? 'text-muted' : ''"
                >Konto Inhaber</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i class="fas fa-check text-success" v-if="datainquiry.requestIban"></i>
                <i class="fas fa-times text-muted" v-if="!datainquiry.requestIban"></i>
              </div>

              <span v-bind:class="!datainquiry.requestIban ? 'text-muted' : ''"
                >IBAN</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i class="fas fa-check text-success" v-if="datainquiry.requestBic"></i>
                <i class="fas fa-times text-muted" v-if="!datainquiry.requestBic"></i>
              </div>

              <span v-bind:class="!datainquiry.requestBic ? 'text-muted' : ''">BIC</span>
            </div>
          </div>

          <div
            style="text-align: left; width: 50%; padding-left: 30px; margin-bottom: 15px"
          >
            <strong>Kontakt</strong>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestHomephone"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestHomephone"
                ></i>
              </div>

              <span v-bind:class="!datainquiry.requestHomephone ? 'text-muted' : ''"
                >Festnetz Nummer</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestMobilephone"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestMobilephone"
                ></i>
              </div>

              <span v-bind:class="!datainquiry.requestMobilephone ? 'text-muted' : ''"
                >Mobiltelefon Nummer</span
              >
            </div>
          </div>
          <!--
          <div
            style="text-align: left; width: 50%; padding-left: 30px; margin-bottom: 15px"
          >
            <strong>Zusätzliche Informationen</strong><br />

            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestGenderId"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestGenderId"
                ></i>
              </div>

              <span v-bind:class="!datainquiry.requestGenderId ? 'text-muted' : ''"
                >Geschlecht</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestReligionId"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestReligionId"
                ></i>
              </div>

              <span v-bind:class="!datainquiry.requestReligionId ? 'text-muted' : ''"
                >Religion</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestDriverslicense"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestDriverslicense"
                ></i>
              </div>

              <span v-bind:class="!datainquiry.requestDriverslicense ? 'text-muted' : ''"
                >Führerschein</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestDlExpiry"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestDlExpiry"
                ></i>
              </div>

              <span v-bind:class="!datainquiry.requestDlExpiry ? 'text-muted' : ''"
                >Führerschein Ablaufdatum</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestFirstaidcourse"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestFirstaidcourse"
                ></i>
              </div>

              <span v-bind:class="!datainquiry.requestFirstaidcourse ? 'text-muted' : ''"
                >Erste Hilfe Kurs</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestFaExpiry"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestFaExpiry"
                ></i>
              </div>

              <span v-bind:class="!datainquiry.requestFaExpiry ? 'text-muted' : ''"
                >EH Kurs Ablaufdatum</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestDisability"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestDisability"
                ></i>
              </div>
              <span v-bind:class="!datainquiry.requestDisability ? 'text-muted' : ''"
                >Behinderung Begünstigtenstatus</span
              >
            </div>
          </div>
          <div
            style="text-align: left; width: 50%; padding-left: 30px; margin-bottom: 15px"
          >
            <strong>Erziehungsberechtigte/r</strong><br />

            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestGuardianName"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestGuardianName"
                ></i>
              </div>
              <span v-bind:class="!datainquiry.requestGuardianName ? 'text-muted' : ''"
                >Name</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestGuardianAddress"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestGuardianAddress"
                ></i>
              </div>
              <span v-bind:class="!datainquiry.requestGuardianAddress ? 'text-muted' : ''"
                >Adresse</span
              >
            </div>
            <div style="clear: both">
              <div style="float: left; padding-left: 15px; padding-right: 13px">
                <i
                  class="fas fa-check text-success"
                  v-if="datainquiry.requestGuardianPhone"
                ></i>
                <i
                  class="fas fa-times text-muted"
                  v-if="!datainquiry.requestGuardianPhone"
                ></i>
              </div>
              <span v-bind:class="!datainquiry.requestGuardianPhone ? 'text-muted' : ''"
                >Telefonnummer</span
              >
            </div>
          </div>
          -->
        </b-row>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="hideModal('modal-employee-datainquiry')">
          <div class="label">Schließen</div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "employeedatainquiry",
  data() {
    return {
      loading: true,
      inquiries: [],
      authUsers: [],
      selectedInquiry: {},
      enumCountry: [],
      enumCitizentype: [],
      enumCivilStatus: [],

      datainquiry: {
        requestGenderId: false,
        requestReligionId: false,
        requestCivilStatusId: true,
        requestCitizenshipId: true,
        requestCitizenTypeId: true,
        requestFirstName: true,
        requestLastName: true,
        requestDob: false,
        requestSocialsecurity: false,
        requestEmail: false,
        requestPhone: false,
        requestHomephone: true,
        requestMobilephone: true,
        requestBankAccountHolder: true,
        requestIban: true,
        requestBic: true,
        requestMdPersonnelId: false,
        requestAddress: true,
        requestPostalCode: true,
        requestCity: true,
        requestCountryId: true,
        requestGuardianName: false,
        requestGuardianAddress: false,
        requestGuardianPhone: false,
        requestCoreEmployeeNumber: false,
        requestMiscInfo: false,
        requestDriverslicense: false,
        requestDlExpiry: false,
        requestFirstaidcourse: false,
        requestFaExpiry: false,
        requestDisability: false,
      },
    };
  },
  props: ["employee"],
  created: function () {
    //console.log("CREATED");
  },
  mounted: function () {
    //console.log("MOUNTED");
    if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
      this.refresh();
    }
  },
  computed: {
    ...mapGetters([
      "companyId",
      "isSuperUser",
      "externalServicesMap",
      "storeSettings",
      "storeArray",
    ]),
    dateDisplay: function () {
      return this.$helpers.getMomentTZ().format("YYYY-MM-DD");
    },
    headerButtons() {
      let view = this;

      let state = {
        buttons: [],
      };

      return state;
    },
    timeZoneList() {
      let result = [];
      /*
            this.timeZones.forEach(i => {
                let item = {label: i, value: i};
                result.push(item);
            })
            */
      return result;
    },
  },
  watch: {
    companyId: function (val, oldVal) {
      this.refresh();
    },
    employee: function (val, oldVal) {
      this.loading = true;
      this.refresh();
    },
  },
  props: ["employee"],
  // methods that implement data logic.
  methods: {
    refresh: function (force = false) {
      this.loading = true;
      let view = this;

      view
        .getInquiries()
        .then((response) => {
          view.inquiries = response.data.rows;
          view.authUsers = response.data.authUsers;

          view.enumCountry = response.data.enumCountry;
          view.enumCitizentype = response.data.enumCitizentype;
          view.enumCivilStatus = response.data.enumCivilStatus;

          for (let i in view.inquiries) {
            view.inquiries[i].createdFrom = "unbekannt";
            view.authUsers.forEach((a) => {
              if (a.id == view.inquiries[i].authuserId) {
                view.inquiries[i].authUser = a;
                view.inquiries[i].createdFrom = a.name;
              }
            });

            view.inquiries[i].created_atMoment = this.$helpers.getMomentFromString(
              view.inquiries[i].created_at.substring(0, 10),
              "YYYY-MM-DD"
            );
            view.inquiries[i].validTillMoment = this.$helpers.getMomentFromString(
              view.inquiries[i].validTill.substring(0, 10),
              "YYYY-MM-DD"
            );

            view.inquiries[i].created_at = view.inquiries[i].created_at.substring(0, 10);
            view.inquiries[i].validTill = view.inquiries[i].validTill.substring(0, 10);
          }

          view.inquiries.sort((a, b) => {
            if (a.validTill > b.validTill) return -1;
            if (a.validTill < b.validTill) return 1;
            return 0;
          });

          /*
                view.documents = response.data;
                for(let i in view.documents){
                    view.isEmpty = false;
                    let obj = view.documents[i];
                    if(view.stores[obj.storeId] == undefined){
                        view.stores[obj.storeId] = {};
                        view.stores[obj.storeId].documents = [];
                    }
                    view.stores[obj.storeId].documents.push(obj);
                }
                */
          view.loading = false;
        })
        .catch(function (error) {
          //view.$helpers.error('Fehler', 'Dokumente konnten nicht geladen werden');
        });
    },

    showModal(ref) {
      this.$refs[ref].show();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },

    openRequestedPopup(inq) {
      this.datainquiry.requestGenderId = inq.requestGenderId;

      this.datainquiry.requestReligionId = inq.requestReligionId;
      this.datainquiry.requestCivilStatusId = inq.requestCivilStatusId;
      this.datainquiry.requestCitizenshipId = inq.requestCitizenshipId;
      this.datainquiry.requestCitizenTypeId = inq.requestCitizenTypeId;
      this.datainquiry.requestFirstName = inq.requestFirstName;
      this.datainquiry.requestLastName = inq.requestLastName;
      this.datainquiry.requestDob = inq.requestDob;
      this.datainquiry.requestSocialsecurity = inq.requestSocialsecurity;

      this.datainquiry.requestEmail = inq.requestEmail;
      this.datainquiry.requestPhone = inq.requestPhone;
      this.datainquiry.requestHomephone = inq.requestHomephone;
      this.datainquiry.requestMobilephone = inq.requestMobilephone;

      this.datainquiry.requestBankAccountHolder = inq.requestBankAccountHolder;
      this.datainquiry.requestIban = inq.requestIban;
      this.datainquiry.requestBic = inq.requestBic;

      this.datainquiry.requestMdPersonnelId = inq.requestMdPersonnelId;
      this.datainquiry.requestAddress = inq.requestAddress;
      this.datainquiry.requestPostalCode = inq.requestPostalCode;
      this.datainquiry.requestCity = inq.requestCity;
      this.datainquiry.requestCountryId = inq.requestCountryId;

      this.datainquiry.requestGuardianName = inq.requestGuardianName;
      this.datainquiry.requestGuardianAddress = inq.requestGuardianAddress;
      this.datainquiry.requestGuardianPhone = inq.requestGuardianPhone;

      this.datainquiry.requestCoreEmployeeNumber = inq.requestCoreEmployeeNumber;
      this.datainquiry.requestMiscInfo = inq.requestMiscInfo;
      this.datainquiry.requestDriverslicense = inq.requestDriverslicense;

      this.datainquiry.requestDlExpiry = inq.requestDlExpiry;
      this.datainquiry.requestFirstaidcourse = inq.requestFirstaidcourse;
      this.datainquiry.requestFaExpiry = inq.requestFaExpiry;
      this.datainquiry.requestDisability = inq.requestDisability;
      this.showModal("modal-employee-datainquiry");
    },
    getInquiries: function () {
      let view = this;
      let url = "/api/sec/employee/datainquiry";

      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/employee/datainquiry"
      );
      url = this.$helpers.appendParam(
        "employeeId",
        view.employee.id,
        url,
        "/api/sec/employee/datainquiry"
      );

      return this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      });
    },
    openInquiry: function (inquiry) {
      this.$parent.selectedInquirySet = true;
      this.selectedInquiry = inquiry;
      this.$parent.dataInquiryMode = 1;

      if (
        this.selectedInquiry.requestFirstName &&
        this.selectedInquiry.firstName != this.employee.firstName
      )
        this.selectedInquiry.checkFirstName = true;
      if (
        this.selectedInquiry.requestLastName &&
        this.selectedInquiry.lastName != this.employee.lastName
      )
        this.selectedInquiry.checkLastName = true;

      if (
        this.selectedInquiry.requestCitizenshipId &&
        this.selectedInquiry.citizenshipId != this.employee.citizenshipId
      )
        this.selectedInquiry.checkCitizenshipId = true;
      if (
        this.selectedInquiry.requestCitizenTypeId &&
        this.selectedInquiry.citizenTypeId != this.employee.citizenTypeId
      )
        this.selectedInquiry.checkCitizenTypeId = true;
      if (
        this.selectedInquiry.requestCivilStatusId &&
        this.selectedInquiry.civilStatusId != this.employee.civilStatusId
      )
        this.selectedInquiry.checkCivilStatusId = true;

      if (
        this.selectedInquiry.requestAddress &&
        this.selectedInquiry.address != this.employee.address
      )
        this.selectedInquiry.checkAddress = true;
      if (
        this.selectedInquiry.requestPostalCode &&
        this.selectedInquiry.postalCode != this.employee.postalCode
      )
        this.selectedInquiry.checkPostalCode = true;
      if (
        this.selectedInquiry.requestCity &&
        this.selectedInquiry.city != this.employee.city
      )
        this.selectedInquiry.checkCity = true;
      if (
        this.selectedInquiry.requestCountryId &&
        this.selectedInquiry.countryId != this.employee.countryId
      )
        this.selectedInquiry.checkCountryId = true;

      if (
        this.selectedInquiry.requestHomephone &&
        this.selectedInquiry.homephone != this.employee.homephone
      )
        this.selectedInquiry.checkHomephone = true;
      if (
        this.selectedInquiry.requestMobilephone &&
        this.selectedInquiry.mobilephone != this.employee.mobilephone
      )
        this.selectedInquiry.checkMobilephone = true;

      if (
        this.selectedInquiry.requestBankAccountHolder &&
        this.selectedInquiry.bankAccountHolder != this.employee.bankAccountHolder
      )
        this.selectedInquiry.checkBankAccountHolder = true;
      if (
        this.selectedInquiry.requestIban &&
        this.selectedInquiry.iban != this.employee.iban
      )
        this.selectedInquiry.checkIban = true;
      if (
        this.selectedInquiry.requestBic &&
        this.selectedInquiry.bic != this.employee.bic
      )
        this.selectedInquiry.checkBic = true;
    },
    stateText: function (inquiry) {
      switch (inquiry.state) {
        case 1:
          return "Ausgefüllt";
        case 2:
          return "Abgeschlossen";
        case 3:
          return "Abgebrochen";
      }
      let initialValue = this.$helpers.getMoment();
      let vT = this.$helpers.getMomentFromString(inquiry.validTill, "YYYY-MM-DD");
      if (vT.isBefore(initialValue)) {
        return "Abgelaufen";
      }
      switch (inquiry.state) {
        case 0:
          return "Angefragt";
        case 4:
          return "Abgelaufen";
      }
      return "unbekannt";
    },
  },
};
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->

<style scoped>
a {
  color: var(--ml);
  cursor: pointer !important;
}

a:hover {
  color: var(--contrast-4);
  cursor: pointer !important;
}

.defaultCursor {
  cursor: default !important;
}
</style>
